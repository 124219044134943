import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import googleIcon from '../../../resources/assets/google_logo.svg';
import Button from '../Widget/Button';

const GoogleButton = (props) => {
    const loginGoogle = useGoogleLogin({
        onSuccess: async tokenResponse => {
            if (tokenResponse && tokenResponse.access_token && props.verifyToken) {
                props.verifyToken({ token: tokenResponse.access_token, type: 'google', website: process.env.website });
            }
        },
        onError: error => props?.handleError(error)
    });

    return (
        <div>
            <Button
                type='border'
                className='btn-choose'
                onClick={() => loginGoogle()}
                disabled={props.disabled}
            >
                <img
                    src={googleIcon}
                    loading='lazy'
                    className='google-icon'
                />
                {props.text}
            </Button>
        </div>
    )
}

export default GoogleButton;