import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import classNames from "classnames";
import { push as nativePush } from 'connected-react-router';
import Rotator from './Widget/Rotator';
import Button from './Widget/Button';
import Video from './Widget/Video';
import Cards from './Widget/Cards';
import * as appActions from "../actions/app_actions";
import {
    getQueryVariable,
    signupCampaignPageLink,
    showBecomeAHost,
    dudaLink
} from "../utils/text";
import BecomeHost from './Advertisement/BecomeHost';
import firebaseClient from '../utils/FirebaseClient';
import sportsIcon from '../../resources/images/sports.png';
import politicsIcon from '../../resources/images/politics.png';
import entertainmentIcon from '../../resources/images/entertainment.png';
import fitnessIcon from '../../resources/images/fitness.png';
import collegeFootballIcon from '../../resources/images/CollegeFootball.png';
import whiskeyIcon from '../../resources/images/Whiskey.jpeg';
import nfnFrame from '../../resources/images/nfb-first-frame.jpg';
import nflLogo from '../../resources/images/NFLAV-Media-Logo.svg';
import Popover from './Widget/Popover';
import appClient from '../utils/AppClient';

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoSrc: null,
            popover: false
        }
    }

    componentDidMount() {
        const { match, showForm, showSignupForm, pushToSignup, appStage, user } = this.props;
        const url = match.url;

        let operation = getQueryVariable('o');

        if (appStage && appStage === appActions.APP_STAGE_LOADED && operation && operation === 'signup') {
            if (!user && showSignupForm) {
                showSignupForm();
            } else if (user && pushToSignup) {
                pushToSignup();
            }
        }

        if (appStage && appStage === appActions.APP_STAGE_LOADED && !user && showForm && url && url !== "/") {
            showForm(true);
        }

        // if (process.env.build && process.env.build === 'ovi' && appStage && appStage !== 3) {
        //     this.setState({
        //         popover: true
        //     }, () => {
        //         appClient.disableScroll(true);
        //     });
        // }

        if (process.env.platform && process.env.platform === 'mobile') {
            this.setState({
                videoSrc: process.env.build && process.env.build === 'nfl-aa' ? require('../../resources/videos/NFLAEditedVideo.mp4') : require('../../resources/videos/NoJoeSizzleMobile.mp4')
            });
        } else {
            this.setState({
                videoSrc: process.env.build && process.env.build === 'nfl-aa' ? `https://firebasestorage.googleapis.com/v0/b/nflalumnimedia-com.appspot.com/o/NFLAEditedVideo.mp4?alt=media&token=e77a4e08-7e18-4956-a11e-01d688685949` : `https://firebasestorage.googleapis.com/v0/b/nofilterbroadcast-net.appspot.com/o/vids%2FNoJoeSizzle.mp4?alt=media&token=c176f30e-00d6-4259-a02d-3e0853d39045`
            });
        }

        firebaseClient.refreshData();
    }

    componentDidUpdate(prevProps) {
        const { match, showForm, showSignupForm, pushToSignup, appStage, user } = this.props;
        const url = match.url;

        let operation = getQueryVariable('o');

        if (appStage && prevProps.appStage !== appStage && appStage === appActions.APP_STAGE_LOADED && operation && operation === 'signup') {
            if (!user && showSignupForm) {
                showSignupForm();
            } else if (user && pushToSignup) {
                pushToSignup();
            }
        }

        if (appStage && prevProps.appStage !== appStage && appStage === appActions.APP_STAGE_LOADED && !user && showForm && url && url !== "/") {
            showForm(true);
        }
    }

    componentWillUnmount() {
        const { popover } = this.state;

        if (popover)
            appClient.disableScroll(false);
    }

    render() {
        const { popover } = this.state
        const { upcomingEvents, featuredEvents, auth, roles, ambassadors, defaults } = this.props;

        let uevents = upcomingEvents ? upcomingEvents.filter((item) => {
            if (item && !firebaseClient.checkHidenIn(item, 'upcoming')) {
                return item;
            }
        }) : null;

        return (
            <div>
                {popover ?
                    <Popover
                        close={() => {
                            this.setState({
                                popover: false
                            }, () => {
                                appClient.disableScroll(false);
                            });
                        }}
                    />
                    : null
                }
                <div className={classNames('home-banner', { 'none': defaults && (!defaults.showHomeBanner || !defaults.homeBannerImg) })}>
                    {defaults && defaults.showHomeBanner && defaults.homeBannerImg ?
                        <a
                            href={defaults.homeBannerLink ? defaults.homeBannerLink : '#'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={defaults.homeBannerImg} className="home-ad-img" loading='lazy' />
                        </a>
                        : null
                    }
                </div>
                {/* <div className='home-ad'>
                    <p>No Filter: <span>Real time, real talk, real people</span></p>
                    <div className='home-ad-buttons'>
                        {showBecomeAHost && (!auth || !roles || (!roles.broadcaster && !roles.administrator)) ?
                            <a href={signupCampaignPageLink}>
                                <Button
                                    type='border-white'
                                    className='btn-home-become'
                                >
                                    become a host
                                     </Button>
                            </a>
                            : null
                        }
                    </div>
                </div> */}
                <Rotator />
                <Cards
                    title='Upcoming Streams'
                    videos={uevents}
                    readMoreLink='/browse?b=upcoming-streams'
                />
                {process.env.build && process.env.build !== 'ovi' ?
                    <div className='home-video-wrapper'>
                        {((!process.env.build) || (process.env.build && process.env.build !== 'nfl-aa')) ? <p className='home-video-title'>Why No Filter</p> : null}
                        {process.env.build && process.env.build === 'nfl-aa' ?
                            <div className='video-out'>
                                <Video
                                    src={this.state.videoSrc}
                                    // poster={nflaFrame}
                                    controls={false}
                                    playsInline={true}
                                />
                            </div>
                            : process.env.build && process.env.build !== 'ovi' ?
                                <div className='video-out'>
                                    <Video
                                        src={this.state.videoSrc}
                                        poster={nfnFrame}
                                        controls={false}
                                        playsInline={true}
                                    />
                                </div>
                                : null
                        }
                        {process.env.build && process.env.build === 'nfl-aa' ?
                            <div className='home-video'>
                                <div className='video-text max-width'>
                                    <a href={`${dudaLink}/raw-soundbites`}>
                                        <Button
                                            type='default'
                                            className='btn-home-video-become no-margin'
                                        >
                                            See Soundbites
                                        </Button>
                                    </a>
                                </div>
                            </div>
                            :
                            <div className='home-video'>
                                <div className='video-text'>
                                    <p>
                                        Our Mission is to develop an unfiltered platform for entertainment, enrichment, inspiration, and connection. No Filter Network aims to empower hosts with an all-in-one, zero-latency streaming solution to connect directly with viewers.
                                        <br />
                                        <br />
                                        Building a community based on Real-Time, Real Talk, Real People.
                                    </p>
                                    {showBecomeAHost && (!auth || !roles || (!roles.broadcaster && !roles.administrator)) ?
                                        <a href={signupCampaignPageLink}>
                                            <Button
                                                type='border'
                                                className='btn-home-video-become'
                                            >
                                                become a host
                                            </Button>
                                        </a>
                                        : null
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    :
                    <div className='home-video-wrapper'>
                        <p className='home-video-title'>Welcome to OVI</p>
                        <div className='home-video'>
                            <div className='video-text max-width'>
                                <p>
                                    Live Streaming and Gaming Ecosystem<br />
                                    Supporting content creators to interact with their fan base in a sub second live streaming environment.<br />
                                    Whether it's producing podcasts, streaming your favourite games or having a conference call, when we say Live, we mean LIVE.
                                </p>
                            </div>
                        </div>
                    </div>
                }
                <Cards
                    title='Popular on the platform'
                    videos={featuredEvents}
                    readMoreLink={`/browse?b=all&f=1`}
                    id='popular'
                />
                {
                    showBecomeAHost && (!auth || !roles || (!roles.broadcaster && !roles.administrator)) ?
                        <BecomeHost />
                        : null
                }
                {process.env.build && process.env.build !== 'ovi' ?
                    <p className='browse-home-title'>browse</p>
                    : null
                }
                {process.env.build && process.env.build !== 'ovi' ?
                    <div className='browse-home-wrapper'>
                        <div className='browse-1'>
                            {process.env.build && process.env.build === 'nfl-aa' ?
                                <div className='browse-img-wrapper nfl'>
                                    <Link to='/browse?b=profootball'>
                                        <img src={nflLogo} loading='lazy' />
                                        <div className='browse-text'>
                                            <p>Pro Football</p>
                                        </div>
                                    </Link>
                                </div>
                                :
                                <div className='browse-img-wrapper sports'>
                                    <Link to='/browse?b=sports'>
                                        <img src={sportsIcon} loading='lazy' />
                                        <div className='browse-text'>
                                            <p>Sports</p>
                                        </div>
                                    </Link>
                                </div>
                            }
                        </div>
                        <div className='browse-2'>
                            {process.env.build && process.env.build === 'nfl-aa' ?
                                <div className='browse-img-wrapper politics'>
                                    <Link to='/browse?b=collegefootball'>
                                        <img src={collegeFootballIcon} loading='lazy' />
                                        <div className='browse-text'>
                                            <p>College Football</p>
                                        </div>
                                    </Link>
                                </div>
                                :
                                <div className='browse-img-wrapper politics'>
                                    <Link to='/browse?b=politics'>
                                        <img src={politicsIcon} loading='lazy' />
                                        <div className='browse-text'>
                                            <p>Politics</p>
                                        </div>
                                    </Link>
                                </div>}
                            {process.env.build && process.env.build === 'nfl-aa' ?
                                <div className='browse-img-wrapper politics'>
                                    <Link to='/browse?b=lifestyle'>
                                        <img src={whiskeyIcon} loading='lazy' />
                                        <div className='browse-text'>
                                            <p>Lifestyle</p>
                                        </div>
                                    </Link>
                                </div>
                                :
                                <div className='browse-image-flex'>
                                    <div className='browse-img-wrapper entertainment'>
                                        <Link to='/browse?b=entertainment'>
                                            <img src={entertainmentIcon} loading='lazy' />
                                            <div className='browse-text'>
                                                <p>Entertainment</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className='browse-img-wrapper fitness'>
                                        <Link to='/browse?b=fitness'>
                                            <img src={fitnessIcon} loading='lazy' />
                                            <div className='browse-text'>
                                                <p>Fitness</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    : null
                }
                {process.env.build && process.env.build !== 'ovi' ?
                    <Cards
                        title='hosts'
                        videos={ambassadors}
                        hosts={true}
                        readMoreLink='/hosts'
                    />
                    : null
                }
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appStage: state.app.stage,
        auth: state.firebase.auth,
        roles: state.firebase.roles,
        user: state.firebase.user,
        upcomingEvents: state.firebase.events.upcoming,
        featuredEvents: state.firebase.events.featured,
        ambassadors: state.firebase.ambassadors,
        defaults: state.firebase.defaults
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showForm: () => {
            dispatch(appActions.showLogIn(true));
        },
        showSignupForm: () => {
            dispatch(appActions.showLogIn(true, false, true));
        },
        pushToSignup: () => {
            dispatch(nativePush('/signup?checkout=1'));
        }
    };
};

const HomeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);

export default HomeContainer;
