import React, { Component } from 'react';
import { strings } from '../../translations/localizedStrings';

export default class Copyright extends Component {
    render() {
        return (
            <div className='copyright'>
                <div className='copyright-item'>
                    {process.env.build && process.env.build === 'nfl-aa' ? `©2023 ${strings.appName}` : process.env.build && process.env.build === 'ovi' ? `©2024 ${strings.fullName}` : `©2021 ${strings.appName}`}
                </div>
            </div>
        )
    }
}
