import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { push as nativePush } from 'connected-react-router';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import classNames from "classnames";
import Message from '../Header/Message';
import Loader from '../Widget/Loader';
import Button from '../Widget/Button';
import PaymentWrapper from '../Payment/PaymentWrapper';
import TipWrapper from '../Payment/TipWrapper';
import VideoWrapper from '../Pages/VideoWrapper';
import Cards from '../Widget/Cards';
import Feedback from "../Widget/Feedback";
import ClickOutside from "../Widget/ClickOutside";
import BecomeHost from '../Advertisement/BecomeHost';
import linkIcon from '../../../resources/assets/link5.svg';
import guestIcon from '../../../resources/assets/guest-icon.png';
import tipIcon from '../../../resources/assets/Tip-1.svg';
import playIcon from '../../../resources/assets/event-play.svg';
import closeIcon from '../../../resources/assets/event-x.svg';
import copyIcon from '../../../resources/assets/event-copy.svg';
import editIcon from '../../../resources/assets/event-edit.svg';
import {
    toSeoUrl,
    getQueryVariable,
    stringToBoolean,
    appLauncherOpenVanityUrl,
    isIOSPrice,
    isIOS,
    checkEventPrice,
    getAnonymousWatcher,
    showBecomeAHost,
    renderCategory,
    checkRecordingAvailable,
    checkRecordingProcessing,
    validShowEndedEvent,
    getUserImg
} from "../../utils/text";
import { strings } from '../../translations/localizedStrings';
import * as appActions from "../../actions/app_actions";
import * as roomActions from "../../actions/room_actions";
import { Editor } from "react-draft-wysiwyg";
import firebaseClient from "../../utils/FirebaseClient";
import fanoutClient from "../../utils/FanoutClient";
import appClient from '../../utils/AppClient';
import { Helmet } from "react-helmet";
import * as mystuffActions from "../../actions/mystuff_actions";
import * as paymentActions from "../../actions/payment_actions";
import { APP_STAGE_LOADED } from '../../actions/app_actions';
import fileDownload from "js-file-download";

class EventForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventItem: props.eventItem || null,
            ticketId: props.ticketId || null,
            paymentWrapper: false,
            tipWrapper: false,
            videoWrapper: false,
            loadVideo: true,
            cancelModal: false,
            guestModal: false,
            hideModal: false,
            deleting: false,
            eventSoldTickets: 0,
            streamSoldTickets: 0,
            processing: false,
            ticketsLoaded: false,
            readMore: false,
            readMoreBtn: false,
            feedback: false,
            feedbackItem: null,
            message: false,
            messageText: false,
            entryApproved: false,
            tooltipText: "",
            error: '',
            guestLink: null,
            guestMode: false,
            disableGetRecording: false,
            disableGetAudio: false,
            guestNameForm: false,
            guestName: ''
        }

        this.descRef = React.createRef();
        this.editorRef = React.createRef();
        this.handleStreamingJoinResponse = this.handleStreamingJoinResponse.bind(this);
        this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
        this.checkToJoinRoom = this.checkToJoinRoom.bind(this);
        this.checkUserTickets = this.checkUserTickets.bind(this);
        this.closeFeedback = this.closeFeedback.bind(this);
        this.handleClickFeedback = this.handleClickFeedback.bind(this);
        this.join = this.join.bind(this);
        this.handleBuyTicket = this.handleBuyTicket.bind(this);
        this.isEllipsisActive = this.isEllipsisActive.bind(this);
        this.unmountComponent = this.unmountComponent.bind(this);
        this.handleCopy = this.handleCopy.bind(this);
        this.handleCopyGuestLink = this.handleCopyGuestLink.bind(this);
        this.handleGetGuestLink = this.handleGetGuestLink.bind(this);
        this.openLinks = this.openLinks.bind(this);
        this.joinAnonymous = this.joinAnonymous.bind(this);
        this.handleChangeGuestName = this.handleChangeGuestName.bind(this);
        fanoutClient.on('streamingJoin', this.handleStreamingJoinResponse);
    }

    checkToJoinRoom() {
        const { entryApproved, ticketId, guestMode } = this.state;
        const { user, role, eventItem } = this.props;

        if (entryApproved && ticketId && role &&
            (user && eventItem &&
                eventItem.status && (eventItem.status !== 'ended' && eventItem.status !== 'cancelled')
                && eventItem.uid && user.uid && (
                    !(eventItem.uid === user.uid) &&
                    !firebaseClient.checkCoHost(eventItem, user.uid) &&
                    !firebaseClient.checkGuest(eventItem, user.uid)
                    && !guestMode
                    && !firebaseClient.checkModerator(eventItem, user.uid)) &&
                !this.checkTicketId())) {
            this.join();
        }

    }

    componentDidMount() {
        window.addEventListener("beforeunload", this.handleBeforeUnload);
        this.checkUserTickets.call(this);

        if (this.checkGIId()) {
            this.setState({
                guestMode: true
            });
        }

        if (this.props.eventItem && this.props.eventItem.id) {
            this.setState({ eventItem: this.props.eventItem });
        }

        if (this.props.eventItem && this.props.eventItem.id && this.props.user) {
            this.checkGetSoldTickets();
        }

        if (this.props.ticketId) {
            this.setState({ ticketId: this.props.ticketId })
        }

        if (!process.env.platform || (process.env.platform && process.env.platform !== 'mobile')) {
            let ua = navigator.userAgent || navigator.vendor || window.opera;
            let userAgentLC = ua && ua.toLowerCase ? ua.toLowerCase() : '';
            let notSupported =
                userAgentLC.indexOf('instagram') > -1
                    || userAgentLC.indexOf('opera') > -1
                    || userAgentLC.indexOf('opr') > -1
                    ? true : false;
            let iosChromeNotify = isIOS() && userAgentLC.indexOf('crios') > -1;
            let message;
            if (iosChromeNotify) {
                message = `Please use safari app in order to use ${strings.fullName}.`;
            } else if (notSupported) {
                message = `Please use safari or chrome app in order to use ${strings.fullName}.`;
            }
            this.setState({
                message: notSupported || iosChromeNotify,
                messageText: message
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { eventItem, entryApproved, guestMode } = this.state;
        const { match, user, appStage, showLogIn, ticketId, userLoaded } = this.props;

        let showPayment = stringToBoolean(getQueryVariable('checkout'));

        let prevMatchId =
            prevProps.match && prevProps.match.params && prevProps.match.params.eventId ?
                prevProps.match.params.eventId :
                prevProps.match && prevProps.match.params && prevProps.match.params.streamId ?
                    prevProps.match.params.streamId :
                    null;
        let matchId =
            match && match.params && match.params.eventId ?
                match.params.eventId :
                match && match.params && match.params.streamId ?
                    match.params.streamId :
                    null;

        if (prevMatchId && matchId && prevMatchId !== matchId) {
            this.setState({
                ticketId: null,
                eventItem: null,
                ticketsLoaded: false
            }, () => {
                this.checkUserTickets.call(this);
            });
        }
        if (prevProps.user != user) {
            this.setState({
                ticketId: this.props.ticketId || null,
                ticketsLoaded: false
            }, () => {
                this.checkUserTickets.call(this);
            });
        }
        if (prevProps.userLoaded !== userLoaded && userLoaded) {
            if (entryApproved && this.props.eventItem && this.props.eventItem.id && !getAnonymousWatcher(this.props.eventItem.id) && this.props.eventItem.anonymousWatchers && !this.props.eventItem.price && !user && !guestMode) {
                this.joinAnonymous();
            }
        }
        if (prevProps.ticketId !== ticketId && ticketId) {
            this.setState({
                ticketId: ticketId
            }, () => {
                this.checkToJoinRoom();
            });
        }
        if (prevState.ticketId !== this.state.ticketId && this.state.ticketId) {
            this.checkToJoinRoom();
        }
        if (prevProps.eventItem !== this.props.eventItem) {
            this.setState({
                eventItem: this.props.eventItem
            }, () => {
                this.checkUserTickets.call(this);
                window.scrollTo(0, 0);
            });
        }
        if ((prevState.eventItem !== eventItem && eventItem) || (prevProps.user !== user && user)) {
            this.checkGetSoldTickets();
        }
        if (appStage && prevProps.appStage !== appStage && appStage === APP_STAGE_LOADED && !user && showLogIn && showPayment) {
            showLogIn(true);
        }
    }

    checkGetSoldTickets() {
        const { eventItem, user } = this.props;

        if (eventItem && eventItem.uid && user && user.uid && (eventItem.uid === user.uid || user.administrator || firebaseClient.checkCoHost(eventItem, user.uid))) {
            firebaseClient.getSoldTicketFS(eventItem.id).then((res) => {
                if (res) {
                    this.setState({
                        eventSoldTickets: res.eventTicketCount ? res.eventTicketCount : 0,
                        streamSoldTickets: res.streamTicketCount ? res.streamTicketCount : 0,
                    });
                }
            });
        }
    }

    showGuestNameForm() {
        let userInfo = JSON.parse(window.localStorage.getItem('userInfo') || '{}');
        this.setState({
            guestNameForm: true,
            guestName: userInfo && userInfo.username ? userInfo.username : ''
        });
    }

    handleChangeGuestName(e) {
        this.setState({
            guestName: e.target.value
        }, () => {
            let userInfo = JSON.parse(window.localStorage.getItem('userInfo') || '{}');
            userInfo.username = e.target.value;
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
        });
    }

    joinAnonymous() {
        const { guestName, guestNameForm } = this.state;
        const { joinUser } = this.props;

        let giid = getQueryVariable('giid');

        if (joinUser) {
            joinUser(
                true,
                guestNameForm && guestName && giid ? guestName : null,
                null,
                null,
                null,
                null,
                null,
                false,
                'audience',
                null,
                guestNameForm && guestName && giid ? giid : null,
            );
        }
    }

    handleStreamingJoinResponse(data) {
        try {
            const { guestMode } = this.state;
            const { eventItem, user, userLoaded } = this.props;
            console.log("handleStreamingJoinResponse response received from server", data);
            if (data && data.status && data.status === 200) {
                this.setState({
                    entryApproved: true
                }, () => {
                    if (eventItem && eventItem.id && !getAnonymousWatcher(eventItem.id) && eventItem.anonymousWatchers && !eventItem.price && !user && userLoaded && !guestMode) {
                        this.joinAnonymous();
                    } else {
                        this.checkToJoinRoom();
                    }
                });
            } else {
                this.setState({
                    isError: true,
                    errorCode: data && data.status || 404
                });
            }
        }
        catch (error) {
            console.error("Failed handleStreamingJoinResponse", error, data);
            this.setState({
                isError: true
            });
        }
    }

    handleClickFeedback(item) {
        this.setState({
            feedback: true,
            feedbackItem: item
        }, () => {
            appClient.disableScroll(true);
        });
    }

    closeFeedback() {
        this.setState({
            feedback: false,
            feedbackItem: null
        }, () => {
            appClient.disableScroll(false);
        });
    }

    checkUserTickets() {
        const { match } = this.props || {};

        const { tickets, user, appStage, showLogIn } = this.props;

        if ((!match || !match.params || !match.params.streamId) && (!match || !match.params || !match.params.eventId))
            return;

        if (!this.state.eventItem || !this.state.eventItem.uid || (!user && appStage && appStage !== APP_STAGE_LOADED))
            return;

        let streamId = match.params.eventId ? match.params.eventId : match.params.streamId;
        let authorId = this.state.eventItem ? this.state.eventItem.uid : null;

        // Check current user tickets for selected stream
        firebaseClient.checkUserTickets(streamId, authorId, process.env.website).then(result => {
            let found = false;

            this.setState({
                ticketsLoaded: true
            });

            if (result && result.data && result.data.length) {
                result.data.forEach(payment => {
                    if (payment.eventId === streamId || payment.showId === "*") {
                        // console.log('found', payment);
                        if (payment.paymentStatus === 'success' || // Listener
                            payment.status == "confirmed" // Cohost
                        ) {
                            found = true;
                            let id = payment.paymentId || payment.id;
                            if (payment && this.checkGIId() && ((!payment.role) || (payment.role && payment.role !== 'host' && payment.role !== 'cohost' && payment.role !== 'guest' && payment.role !== 'guest_speaker' && payment.role !== 'moderator'))) {
                                let giid = getQueryVariable('giid');

                                if (user && giid) {
                                    fanoutClient.createMeGuestTicket(giid).then((id) => {
                                        if (id) {
                                            fanoutClient.removeReconnectToken(user.email);
                                            this.setState({
                                                ticketId: id
                                            }, () => {
                                                this.join();
                                            });
                                        } else {
                                            this.setState({
                                                guestMode: false
                                            });
                                        }
                                    }).catch((e) => {
                                        console.error("Failed createMeGuestTicket", e);
                                        this.setState({
                                            guestMode: false
                                        });
                                    });
                                } else if (!user && showLogIn) {
                                    this.showGuestNameForm();
                                }
                            } else if (id) {
                                this.setState({
                                    ticketId: id
                                });
                            }
                        }
                        if (payment.showId && payment.showId === "*" && payment.type === "subscription") {
                            found = true;
                            let id = payment.paymentId || payment.id;
                            if (this.checkGIId()) {
                                let giid = getQueryVariable('giid');

                                if (user && giid) {
                                    fanoutClient.createMeGuestTicket(giid).then((id) => {
                                        if (id) {
                                            fanoutClient.removeReconnectToken(user.email);
                                            this.setState({
                                                ticketId: id
                                            }, () => {
                                                this.join();
                                            });
                                        } else {
                                            this.setState({
                                                guestMode: false
                                            });
                                        }
                                    }).catch((e) => {
                                        console.error("Failed createMeGuestTicket", e);
                                        this.setState({
                                            guestMode: false
                                        });
                                    });
                                } else if (!user && showLogIn) {
                                    this.showGuestNameForm();
                                }
                            } else if (id) {
                                this.setState({
                                    ticketId: id
                                });
                            }
                        }
                    }
                });

                if (this.state.eventItem && this.state.eventItem.uid && !found) {
                    let showPayment = stringToBoolean(getQueryVariable('checkout'));

                    if (showPayment) {
                        if (user && user.id && this.state.eventItem.uid !== user.id && !(((this.state.eventItem.type && this.state.eventItem.type === 'stream') || (this.state.eventItem.status && this.state.eventItem.status === 'ended')) && !this.state.eventItem.archivePrice)) {
                            this.setState({
                                paymentWrapper: showPayment
                            });
                        } else if (!user && showLogIn) {
                            showLogIn(true);
                        }
                    }
                }

                if (this.state.eventItem) {
                    let showVideo = stringToBoolean(getQueryVariable('video'));

                    if (showVideo && this.state.eventItem && ((this.state.eventItem.type && this.state.eventItem.type === 'stream') || (this.state.eventItem.status && this.state.eventItem.status === 'ended'))) {
                        if (this.state.ticketId && ((this.state.eventItem.type && this.state.eventItem.type === 'stream') || (this.state.eventItem.status && this.state.eventItem.status === 'ended')) && ((this.state.eventItem.parts && this.state.eventItem.parts.length && !this.state.eventItem.recordingStatus) || checkRecordingAvailable(this.state.eventItem))
                            || ((this.state.eventItem.type && this.state.eventItem.type === 'stream') || (this.state.eventItem.status && this.state.eventItem.status === 'ended')) && this.state.ticketId && ((this.state.eventItem.parts && this.state.eventItem.parts.length && !this.state.eventItem.recordingStatus) || checkRecordingAvailable(this.state.eventItem))
                            || this.state.eventItem.uid && user && user.uid && user.uid === this.state.eventItem.uid && ((this.state.eventItem.type && this.state.eventItem.type === 'stream') || (this.state.eventItem.status && this.state.eventItem.status === 'ended')) && this.state.eventItem.status && this.state.eventItem.status !== 'cancelled' && ((this.state.eventItem.parts && this.state.eventItem.parts.length && !this.state.eventItem.recordingStatus) || checkRecordingAvailable(this.state.eventItem))) {
                            this.setState({
                                videoWrapper: true
                            });
                        } else if (user && user.id && this.state.eventItem.uid !== user.id && isIOSPrice(((this.state.eventItem.type && this.state.eventItem.type === 'stream') || (this.state.eventItem.status && this.state.eventItem.status === 'ended')) ? this.state.eventItem.price : this.state.eventItem.archivePrice)) {
                            this.handleBuyTicket(this.state.ticketId, this.state.eventItem);
                        } else if (!user && showLogIn) {
                            showLogIn(true);
                        }
                    }
                }
            } else if (!result || !result.data || !result.data.length) {
                if (this.checkGIId()) {
                    let giid = getQueryVariable('giid');

                    if (user && giid) {
                        fanoutClient.createMeGuestTicket(giid).then((id) => {
                            if (id) {
                                found = true;
                                fanoutClient.removeReconnectToken(user.email);
                                this.setState({
                                    ticketId: id
                                }, () => {
                                    this.join();
                                });
                            } else {
                                this.setState({
                                    guestMode: false
                                });
                            }
                        }).catch((e) => {
                            console.error("Failed createMeGuestTicket", e);
                            this.setState({
                                guestMode: false
                            });
                        });
                    } else if (!user && showLogIn) {
                        this.showGuestNameForm();
                    }
                }

                if (!found) {
                    let processingTicket = [tickets].filter(item => item.paymentStatus && item.paymentStatus === 'in_processing' && item.eventId === streamId);

                    if (processingTicket.length > 0) {
                        found = true;
                        this.setState({
                            ticketId: processingTicket[0].id,
                        });
                    }
                }

                if (this.state.eventItem && this.state.eventItem.uid && !found) {
                    let showPayment = stringToBoolean(getQueryVariable('checkout'));

                    if (showPayment) {
                        if (user && user.id && this.state.eventItem.uid !== user.id && !(((this.state.eventItem.type && this.state.eventItem.type === 'stream') || (this.state.eventItem.status && this.state.eventItem.status === 'ended')) && !this.state.eventItem.archivePrice)) {
                            this.setState({
                                paymentWrapper: showPayment
                            });
                        } else if (!user && showLogIn) {
                            showLogIn(true);
                        }
                    }
                }

                if (this.state.eventItem) {
                    let showVideo = stringToBoolean(getQueryVariable('video'));

                    if (showVideo && this.state.eventItem && ((this.state.eventItem.type && this.state.eventItem.type === 'stream') || (this.state.eventItem.status && this.state.eventItem.status === 'ended'))) {
                        if (this.state.ticketId && ((this.state.eventItem.type && this.state.eventItem.type === 'stream') || (this.state.eventItem.status && this.state.eventItem.status === 'ended')) && ((this.state.eventItem.parts && this.state.eventItem.parts.length && !this.state.eventItem.recordingStatus) || checkRecordingAvailable(this.state.eventItem))
                            || ((this.state.eventItem.type && this.state.eventItem.type === 'stream') || (this.state.eventItem.status && this.state.eventItem.status === 'ended')) && this.state.ticketId && ((this.state.eventItem.parts && this.state.eventItem.parts.length && !this.state.eventItem.recordingStatus) || checkRecordingAvailable(this.state.eventItem))
                            || this.state.eventItem.uid && user && user.uid && user.uid === this.state.eventItem.uid && ((this.state.eventItem.type && this.state.eventItem.type === 'stream') || (this.state.eventItem.status && this.state.eventItem.status === 'ended')) && this.state.eventItem.status && this.state.eventItem.status !== 'cancelled' && ((this.state.eventItem.parts && this.state.eventItem.parts.length && !this.state.eventItem.recordingStatus) || checkRecordingAvailable(this.state.eventItem))) {
                            this.setState({
                                videoWrapper: true
                            });
                        } else if (user && user.id && this.state.eventItem.uid !== user.id && isIOSPrice(((this.state.eventItem.type && this.state.eventItem.type === 'stream') || (this.state.eventItem.status && this.state.eventItem.status === 'ended')) ? this.state.eventItem.price : this.state.eventItem.archivePrice)) {
                            this.handleBuyTicket(this.state.ticketId, this.state.eventItem);
                        } else if (!user && showLogIn) {
                            showLogIn(true);
                        }
                    }
                }
            }
        });
    }

    componentWillUnmount() {
        this.unmountComponent();
    }

    handleBeforeUnload() {
        this.unmountComponent();
    }

    unmountComponent() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
        fanoutClient.removeListener('streamingJoin', this.handleStreamingJoinResponse);
    }

    handleBuyTicket(ticketId, item) {
        const { user, showLogIn, processingPayment, successPayment, resetPaymentValue } = this.props;

        if (item && ((item.type && item.type === 'stream') || (item.status && item.status === 'ended')) && !checkEventPrice(item)) {
            if (user && ((item.parts && item.parts.length && !item.recordingStatus) || checkRecordingAvailable(item))) {
                this.setState({
                    videoWrapper: true,
                    loadVideo: false
                }, () => {
                    if (!ticketId) {
                        processingPayment();
                        firebaseClient.reserveTicket(item).then((res) => {
                            if (res && res.data) {
                                successPayment();
                                this.setState({
                                    loadVideo: true
                                });
                                setTimeout(() => {
                                    this.handleShowStreamWrapper(res.data.paymentId)
                                    resetPaymentValue();
                                }, 5000);
                            }
                        });
                    }
                });
            } else if (!user && showLogIn) {
                showLogIn(true);
            }
        } else if (item && !checkEventPrice(item)) {
            if (user) {
                this.setState({
                    paymentWrapper: true
                }, () => {
                    processingPayment();
                    firebaseClient.reserveTicket(item).then((res) => {
                        if (res && res.data) {
                            successPayment();
                            setTimeout(() => {
                                this.handleShowStreamWrapper(res.data.paymentId)
                                resetPaymentValue();
                            }, 5000);
                        }
                    });
                });
            } else if (!user && showLogIn) {
                showLogIn(true);
            }
        } else if (!ticketId) {
            if (process.env.platform && process.env.platform === 'mobile' && item && item.type && item.id && (((item.type && item.type === 'stream') || (item.status && item.status === 'ended') && item.archivePrice) || (item.status && item.status !== 'ended' && item.price))) {
                appLauncherOpenVanityUrl(item.type, item.id);
            } else {
                this.setState({
                    paymentWrapper: true
                }, () => {
                    if (!user && showLogIn) {
                        showLogIn(true);
                    }
                });
            }
        }
    }

    isEllipsisActive() {
        let valid = false;

        let editorRef = this.editorRef ? this.editorRef : null;
        let elementRef = this.descRef && this.descRef.current ? this.descRef.current : null;

        if (editorRef && editorRef.editorContainer && editorRef.editorContainer.children && editorRef.editorContainer.children.length && editorRef.editorContainer.children[0] && (editorRef.editorContainer.children[0].offsetHeight < editorRef.editorContainer.children[0].scrollHeight)) {
            valid = true;
        } else if (elementRef && (elementRef.offsetHeight < elementRef.scrollHeight)) {
            valid = true;
        }

        return valid;
    }

    handleCopy(item, ref) {
        if (item && item.id) {
            const link = item.sharedLink ?
                item.sharedLink :
                `https://${firebaseClient.getDomain()}/event/${item.id}`;

            let onCopyOk = () => {
                console.log('Copy link success', link);
                if (ref) {
                    this.setState({
                        tooltipText: "Copied!"
                    }, () => {
                        ReactTooltip.show(ref);
                        setTimeout(() => {
                            ReactTooltip.hide(ref);
                        }, 2000);
                    });
                }
            }
            let onCopyNOk = (e) => {
                console.error(`Could not write link [${link}] to clipboard: ${e.message}`)
                if (ref) {
                    this.setState({
                        tooltipText: "Could not copy"
                    }, () => {
                        ReactTooltip.show(ref);
                        setTimeout(() => {
                            ReactTooltip.hide(ref);
                        }, 2000);
                    });
                }
            }

            navigator.clipboard.writeText(link)
                .then(onCopyOk)
                .catch(onCopyNOk);
        }
    }

    handleGetGuestLink() {
        const { guestLink } = this.state;
        const { eventItem } = this.props;

        this.setState({
            guestModal: true
        }, () => {
            if (guestLink) {
                return;
            }

            if (eventItem && eventItem.id) {
                fanoutClient.getMeInvitationLink(eventItem.id).then((link) => {
                    if (link) {
                        this.setState({
                            guestLink: link
                        })
                    }
                }).catch((e) => {
                    console.error('ERROR', e.message);
                    this.setState({
                        error: "Error copying link"
                    });
                });
            } else {
                this.setState({
                    error: "Error copying link"
                });
            }
        });
    }

    handleCopyGuestLink(ref) {
        const { guestLink } = this.state;

        if (guestLink) {
            let onCopyOk = () => {
                console.log('Copy link success', guestLink, ref);
                if (ref) {
                    this.setState({
                        tooltipText: "Copied!"
                    }, () => {
                        ReactTooltip.show(ref);
                        this.setState({
                            processing: false,
                            guestModal: false
                        });
                        setTimeout(() => {
                            ReactTooltip.hide(ref);
                        }, 2000);
                    });
                }
            }
            let onCopyNOk = (e) => {
                console.error(`Could not write link [${guestLink}] to clipboard: ${e.message}`)
                if (ref) {
                    this.setState({
                        tooltipText: "Could not copy"
                    }, () => {
                        ReactTooltip.show(ref);
                        this.setState({
                            processing: false,
                            guestModal: false
                        });
                        setTimeout(() => {
                            ReactTooltip.hide(ref);
                        }, 2000);
                    });
                }
            }

            navigator.clipboard.writeText(guestLink)
                .then(onCopyOk)
                .catch(onCopyNOk);
        }
    }

    handleResetDefaultValue() {
        this.setState({
            paymentWrapper: false,
            videoWrapper: false,
            tipWrapper: false
        });
    }

    handleDeleteItem(id, type, website = null) {
        //console.log('handleDeleteItem', id, type)
        this.setState({ deleting: true });
        firebaseClient.cancelEventOrPost({ postId: id, eventId: id, type: type, website: website }).then((result) => {
            // console.log("result", result);
            let eventItem = this.state.eventItem;
            eventItem.status = "cancelled";
            this.setState({ deleting: false, cancelModal: false });
            this.setState({
                eventItem: eventItem
            }, () => {
                const { setEventItem } = this.props;

                if (setEventItem) {
                    setEventItem(eventItem);
                }
            });
        });
    }

    handleHideItem(id, uid) {
        const { tickets } = this.props;
        let ticketId = tickets[id].id;
        this.setState({ deleting: true });
        firebaseClient.hideCohostVideo({ streamId: id, uid: uid, ticketId: ticketId }).then((result) => {
            // console.log("result", result);
            let eventItem = this.state.eventItem;

            if (eventItem.cohosts && eventItem.cohosts.length > 0) {
                eventItem.cohosts.forEach(cohost => {
                    if (cohost.id === uid) {
                        cohost.status = "hidden";
                    }
                })
            } else if (eventItem.guestSpeakers && eventItem.guestSpeakers.length > 0) {
                eventItem.guestSpeakers.forEach(guest => {
                    if (guest.id === uid) {
                        guest.status = "hidden";
                    }
                })
            }

            this.setState({
                deleting: false,
                hideModal: false,
                eventItem: eventItem
            }, () => {
                const { setEventItem } = this.props;

                if (setEventItem) {
                    setEventItem(eventItem);
                }
            });
        });
    }

    handleShowStreamWrapper(id) {

        const { eventItem } = this.state;
        const { user, addProcessingTicket } = this.props;

        let ticket = {};

        ticket.id = eventItem.id + "_processing";
        ticket.eventId = eventItem.id;
        ticket.paymentId = id;
        ticket.paymentStatus = "in_processing";
        ticket.status = "processing";
        ticket.type = `${eventItem.type}-ticket`;
        ticket.role = "listener";
        ticket.userId = user.uid;

        if (addProcessingTicket) {
            addProcessingTicket(ticket);
        }
        this.setState({
            paymentWrapper: false,
            ticketId: id
        });
    }

    handleShowVideoWrapper() {
        this.setState({
            videoWrapper: true
        });
    }

    checkTicketId() {
        const { ticketVerified } = this.props;
        let value = false;
        let ticketId = getQueryVariable('ticketId');

        if (ticketId && ticketVerified) {
            value = true;
        }

        return value;
    }

    checkGIId() {
        let value = false;
        let giid = getQueryVariable('giid');

        if (giid) {
            value = true;
        }

        return value;
    }

    join() {
        const { ticketId, entryApproved } = this.state;
        const { user, role, joinUser } = this.props;

        if (((user && user.username && user.email) || this.checkTicketId()) && role && joinUser && entryApproved) {
            const storageItem = {
                username: user && user.username ? user.username.trim() : null,
                email: user && user.email ? user.email.trim() : null
            }

            localStorage.setItem('userInfo', JSON.stringify(storageItem));
            joinUser(
                true,
                user && user.username ? user.username : null,
                null,
                null,
                null,
                getUserImg(user),
                user && user.email ? user.email : null,
                false,
                role,
                ticketId ? ticketId : null
            );
        }
    }

    checkShowCopyLink() {
        const { guestMode } = this.state;
        const { eventItem, user } = this.props;
        let retVal = true;

        if (!user) {
            retVal = false;
        } else if (eventItem && eventItem.visibility && eventItem.visibility === 'public' && !eventItem.archiveEnabled && ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended'))) {
            retVal = false;
        } else if (eventItem && eventItem.visibility && eventItem.visibility === 'private' && eventItem.archiveEnabled && user && eventItem.uid && user.uid && (!(eventItem.uid === user.uid) && !firebaseClient.checkCoHost(eventItem, user.uid) && !firebaseClient.checkGuest(eventItem, user.uid) && !guestMode && !firebaseClient.checkModerator(eventItem, user.uid)) && ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended'))) {
            retVal = false;
        } else if (eventItem && eventItem.visibility && eventItem.visibility === 'private' && !eventItem.archiveEnabled && ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended'))) {
            retVal = false;
        }

        return retVal;
    }

    checkShowCopyGuestLink() {
        const { eventItem, user, connectedToWS } = this.props;
        let retVal = false;

        let now = Date.now();
        let period = (eventItem && eventItem.estDuration ? eventItem.estDuration : 60) * 60 * 1000;

        if (connectedToWS && eventItem && ((eventItem.startDate && eventItem.startDate + period > now) || (eventItem.status && eventItem.status === 'started')) && eventItem.id && eventItem.type && eventItem.type !== 'stream' && eventItem.status && eventItem.status !== 'ended' && user && eventItem.uid && user.uid && ((eventItem.uid === user.uid) || firebaseClient.checkCoHost(eventItem, user.uid) || firebaseClient.checkModerator(eventItem, user.uid))) {
            retVal = true;
        }

        return retVal;
    }

    checkShowWatchButton() {
        const { eventItem, user } = this.props;
        let retVal = true;

        if (eventItem && ((!eventItem.archiveEnabled && !user) || (!eventItem.archiveEnabled && user && eventItem.uid && user.uid && (!(eventItem.uid === user.uid) && !firebaseClient.checkCoHost(eventItem, user.uid)))) && ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended'))) {
            retVal = false;
        } else if (validShowEndedEvent(eventItem, user)) {
            retVal = false;
        }

        return retVal;
    }

    openLinks(item, type = 'recording') {
        if (item && item.id && ((item.parts && item.parts.length) || checkRecordingAvailable(item))) {
            if (type === 'audio') {
                this.setState({ disableGetAudio: true });
            } else {
                this.setState({ disableGetRecording: true });
            }
            firebaseClient.getVideoLinks(item, true, true).then((res) => {
                if (res && res.length && res !== 'processing' && res !== 'error') {
                    res.map((video) => {
                        if (video && video.url && video.newRecording) {
                            console.log('VIDEO', video);
                            if (type === 'audio' && video.audioLink) {
                                axios.get(video.audioLink, {
                                    responseType: 'blob',
                                }).then(res => {
                                    fileDownload(res.data, `${item.eventName}${item.startDate ? `(${moment(new Date(item.startDate)).format('MM-DD-YYYY')})` : ''}.mp3`)
                                });
                            } else if (type === 'recording') {
                                axios.get(video.url, {
                                    responseType: 'blob',
                                }).then(res => {
                                    fileDownload(res.data, `${item.eventName}${item.startDate ? `(${moment(new Date(item.startDate)).format('MM-DD-YYYY')})` : ''}.mp4`)
                                });
                            }
                        } else {
                            window.open(video.url);
                        }
                        return video;
                    });

                    if (type === 'audio') {
                        this.setState({ disableGetAudio: false });
                    } else {
                        this.setState({ disableGetRecording: false });
                    }
                }
            }).catch((e) => {
                console.error(e);
                if (type === 'audio') {
                    this.setState({ disableGetAudio: false });
                } else {
                    this.setState({ disableGetRecording: false });
                }
            });
        }
    }

    render() {
        const { ticketId, paymentWrapper, tipWrapper, videoWrapper, loadVideo, eventSoldTickets, streamSoldTickets, message,
            messageText, guestMode, disableGetRecording, disableGetAudio, cancelModal, guestModal, guestLink, hideModal, deleting, ticketsLoaded,
            readMore, readMoreBtn, feedback, feedbackItem, tooltipText, error, processing, entryApproved, guestNameForm, guestName } = this.state;
        const { eventItem, user, upcomingEvents, auth, appStage, userLoaded, orientation, connectedToWS } = this.props;

        let uevents = upcomingEvents ? upcomingEvents.filter((event) => {
            if (event && !firebaseClient.checkHidenIn(event, 'upcoming') && event.id && eventItem && eventItem.id && event.id !== eventItem.id && ((event.uid === eventItem.uid) || (user && user.uid && firebaseClient.checkCoHost(event, user.uid)))) {
                return event;
            }
        }) : null;

        console.log("Event status", eventItem, this.props.ticketId)
        let now = Date.now();
        let period = (eventItem && eventItem.estDuration ? eventItem.estDuration : 60) * 60 * 1000;
        let enterLivePeriod = (eventItem && eventItem.startDate ? eventItem.startDate - (30 * 60 * 1000) : null);

        return (
            <div>
                {eventItem ? <Helmet>
                    {/*Primary Meta Tags*/}
                    <meta charSet="utf-8" />
                    <title>{`${strings.title} - ${eventItem.title}`}</title>
                    <meta name="title" content={`${strings.title} - ${eventItem.title}`} />
                    <meta name="description" content={eventItem.descriptionText || eventItem.description} />
                    <link rel="canonical" href={`${process.env.domain}/event/${eventItem.id}/${toSeoUrl(eventItem.title || eventItem.eventName)}`} />
                    {/*Open Graph / Facebook*/}
                    <meta property="og:url" content={`${process.env.domain}/event/${eventItem.id}/${toSeoUrl(eventItem.title || eventItem.eventName)}`} />
                    <meta property="og:title" content={`${strings.title} - ${eventItem.title}`} />
                    <meta property="og:description" content={eventItem.descriptionText || eventItem.description} />
                    <meta property="og:image"
                        content={firebaseClient.getEventCardPicture(eventItem)} />
                    {/*Twitter*/}
                    <meta property="twitter:url"
                        content={`${process.env.domain}/event/${eventItem.id}/${toSeoUrl(eventItem.title || eventItem.eventName)}`} />
                    <meta property="twitter:title" content={`${strings.title} - ${eventItem.title}`} />
                    <meta property="twitter:description" content={eventItem.descriptionText || eventItem.description} />
                    <meta property="twitter:image"
                        content={firebaseClient.getEventCardPicture(eventItem)} />
                </Helmet> : null}
                {eventItem && appStage && appStage === APP_STAGE_LOADED ?
                    <div className='event-page-wrapper'>
                        <div className='event-page-details' style={{ backgroundImage: `url(${firebaseClient.getEventPagePicture(eventItem, orientation)})` }}>
                            <div className='event-details'>
                                {eventItem && eventItem.status && eventItem.status !== "cancelled" ?
                                    <div className='event-header-buttons'>
                                        {ticketId && connectedToWS &&
                                            ((user && eventItem && eventItem.uid && user.uid &&
                                                (eventItem.uid === user.uid || firebaseClient.checkCoHost(eventItem, user.uid) || firebaseClient.checkGuest(eventItem, user.uid) || guestMode || firebaseClient.checkModerator(eventItem, user.uid)) &&
                                                (user.administrator || enterLivePeriod && enterLivePeriod < now)
                                            ) || this.checkTicketId()
                                            ) &&
                                            ((eventItem.startDate && eventItem.startDate + period > now) || (eventItem.status && eventItem.status === 'started')) &&
                                            ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status !== 'ended' && eventItem.status !== 'cancelled')) ?
                                            <div
                                                className={classNames('event-header-button join-e', { 'disabled-button': !entryApproved })}
                                                onClick={this.join}
                                            >
                                                <img src={playIcon} loading='lazy' />
                                                <p className='event-header-text'>
                                                    Enter
                                                    <br />
                                                    Live
                                                    <br />
                                                    Stream
                                                </p>
                                            </div>
                                            : (ticketId && connectedToWS && eventItem.startDate + period > now && eventItem.status && eventItem.status !== 'started' && eventItem.type && eventItem.type !== 'stream' && eventItem.status && eventItem.status !== 'ended') ?
                                                <div className='event-header-button copy-e disabled-e'>
                                                    <img src={closeIcon} loading='lazy' />
                                                    <p className='event-header-text'>
                                                        Event<br />Has Not<br />Started
                                                    </p>
                                                </div>
                                                : null
                                        }
                                        {user && user.uid && eventItem && eventItem.id && eventItem.uid && user.uid === eventItem.uid ?
                                            <div
                                                className='event-header-button close-e'
                                                onClick={() => this.setState({
                                                    cancelModal: true
                                                })}
                                            >
                                                <img src={closeIcon} loading='lazy' />
                                                {eventItem && eventItem.type == 'event' && eventItem.startDate && eventItem.startDate + period > now ?
                                                    <p className='event-header-text'>
                                                        Cancel<br />Event
                                                    </p>
                                                    :
                                                    <p className='event-header-text'>
                                                        Delete<br />Event
                                                    </p>
                                                }
                                            </div>
                                            : null
                                        }
                                        {/* {user && user.uid && eventItem && ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended')) && firebaseClient.checkCoHost(eventItem, user.uid) ?
                                            <div
                                                className='event-header-button close-e'
                                                onClick={() => this.setState({
                                                    hideModal: true
                                                })}
                                            >
                                                <img src={closeIcon} loading='lazy' />
                                                <p className='event-header-text'>
                                                    Hide<br />Stream
                                                </p>
                                            </div>
                                            : null
                                        } */}
                                        {this.checkShowCopyLink() ?
                                            <div className='event-header-button copy-e'
                                                onClick={() => this.handleCopy(eventItem, this.firstTooltip)}
                                                data-for="event-copy-button"
                                                data-tip
                                                ref={ref => this.firstTooltip = ref}
                                            >
                                                <img src={linkIcon} loading='lazy' />
                                                <p className='event-header-text'>
                                                    Copy<br />Audience<br />Link
                                                </p>
                                                <ReactTooltip
                                                    id="event-copy-button"
                                                    isCapture={true}
                                                    className="copy-tooltip"
                                                    place="top"
                                                    type="dark"
                                                    effect="solid"
                                                    event="none"
                                                    eventOff="click"
                                                    delayHide={2000}
                                                >
                                                    {tooltipText}
                                                </ReactTooltip>
                                            </div>
                                            : null
                                        }
                                        {this.checkShowCopyGuestLink() ?
                                            <div className={classNames('event-header-button copy-e', { 'disabled-e': processing })}
                                                onClick={this.handleGetGuestLink}
                                                data-for="event-copy-button"
                                                data-tip
                                                ref={ref => this.secondTooltip = ref}
                                            >
                                                <img src={guestIcon} loading='lazy' />
                                                <p className='event-header-text'>
                                                    Copy<br />Guest<br />Link
                                                </p>
                                                <ReactTooltip
                                                    id="event-copy-button"
                                                    isCapture={true}
                                                    className="copy-tooltip"
                                                    place="top"
                                                    type="dark"
                                                    effect="solid"
                                                    event="none"
                                                    eventOff="click"
                                                    delayHide={2000}
                                                >
                                                    {tooltipText}
                                                </ReactTooltip>
                                            </div>
                                            : null
                                        }
                                        {eventItem && eventItem.id && eventItem.uid && user && user.uid && user.uid === eventItem.uid && eventItem.status && eventItem.status !== "cancelled" ?
                                            <Link to={`/calendar/${eventItem.id}/${window.btoa('copyEvent')}`}>
                                                <div className='event-header-button copy-e'>
                                                    <img src={copyIcon} loading='lazy' />
                                                    <p className='event-header-text'>
                                                        Duplicate<br />Event
                                                    </p>
                                                </div>
                                            </Link>
                                            : null
                                        }
                                        {eventItem && eventItem.id && eventItem.uid && user && user.uid && user.uid === eventItem.uid && eventItem.startDate && eventItem.startDate + period > now && eventItem.status && (eventItem.status !== "cancelled" && eventItem.status !== "ended") ?
                                            <Link to={`/calendar/${eventItem.id}`}>
                                                <div className='event-header-button edit-e'>
                                                    <img src={editIcon} loading='lazy' />
                                                    <p className='event-header-text'>
                                                        Edit<br />Event
                                                    </p>
                                                </div>
                                            </Link>
                                            : null}
                                        {(ticketId && (user && eventItem && eventItem.acceptTips && eventItem.uid && user.uid && (!(eventItem.uid === user.uid) && !firebaseClient.checkCoHost(eventItem, user.uid) && !firebaseClient.checkGuest(eventItem, user.uid) && !guestMode && !firebaseClient.checkModerator(eventItem, user.uid)) && !this.checkTicketId())) ?
                                            <div className='event-header-button tip-e'
                                                onClick={() => this.setState({ tipWrapper: true })}
                                            >
                                                <img src={tipIcon} loading='lazy' />
                                                <p className='event-header-text'>
                                                    Tip<br />Host
                                                </p>
                                            </div>
                                            : null
                                        }
                                    </div>
                                    : eventItem && eventItem.status && eventItem.status === "cancelled" ?
                                        <div className='event-header-buttons'>
                                            <div className='event-header-button close-e disabled-e'>
                                                <img src={closeIcon} loading='lazy' />
                                                {eventItem && eventItem.type == 'event' && eventItem.startDate && eventItem.startDate + period > now ?
                                                    <p className='event-header-text'>Event<br />Cancelled</p>
                                                    : eventItem && eventItem.type == 'event' ?
                                                        <p className='event-header-text'>Event<br />Deleted</p>
                                                        :
                                                        <p className='event-header-text'>Stream<br />Deleted</p>
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                                <p className='event-title'>{eventItem.title}</p>
                                <Link
                                    to={`/browse?b=${eventItem.cat && eventItem.cat.toLowerCase ? eventItem.cat.toLowerCase() : 'all'}`}
                                    className='event-category'
                                >
                                    {renderCategory(eventItem.cat)}
                                </Link>
                                <Link to={`/profile/${eventItem.uid}`} title={eventItem.author} className='event-author-details'>
                                    <img className='author-img' src={eventItem.authorPic} loading='lazy' />
                                    <div className='author-name'>{eventItem.author}</div>
                                </Link>
                                {eventItem && eventItem.uid && user && user.uid && (eventItem.uid === user.uid || user.administrator || firebaseClient.checkCoHost(eventItem, user.uid)) ?
                                    <div className='event-analytics'>
                                        {((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended')) ?
                                            <p>{`Tickets Sold Live/Recording: ${eventSoldTickets || 0}/${streamSoldTickets || 0}`}</p>
                                            :
                                            <p>{`Tickets Sold: ${eventSoldTickets || 0} of ${eventItem.noOfTickets || 0}`}</p>
                                        }
                                    </div>
                                    : null
                                }
                                {eventItem.description && ((typeof eventItem.description !== 'string' && eventItem.descriptionText) || typeof eventItem.description === 'string') ?
                                    <div>
                                        <div ref={this.descRef} className={classNames('event-description', { 'read-more': readMore })}>
                                            {typeof eventItem.description !== 'string' ?
                                                (
                                                    <Editor
                                                        toolbarHidden
                                                        readOnly
                                                        initialContentState={eventItem.description}
                                                        editorRef={(ref) => this.editorRef = ref}
                                                    />
                                                )
                                                : eventItem.description}
                                        </div>
                                        {readMoreBtn ?
                                            <Button
                                                type='text-blue'
                                                className='btn-read-more'
                                                onClick={() => this.setState({ readMore: !readMore })}
                                            >
                                                {`read ${readMore ? 'less' : 'more'}`}
                                            </Button>
                                            : null
                                        }
                                    </div>
                                    : null
                                }
                                {((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended')) ?
                                    <p className='event-date'>{eventItem && eventItem.startDate ? `${moment(new Date(eventItem.startDate)).format('LL')}` : ''}</p>
                                    :
                                    <p className='event-date'>{eventItem && eventItem.startDate ? `Start ${moment(new Date(eventItem.startDate)).format('LL')} | ${moment(new Date(eventItem.startDate)).format('LT')}` : ''}</p>
                                }
                                <div className='event-buttons'>
                                    {!userLoaded ?
                                        <Button
                                            type='default'
                                            className='btn-event-page'
                                            loading={true}
                                        />
                                        : this.checkShowWatchButton() && !guestMode && ((eventItem && ticketsLoaded && (eventItem.noOfTickets !== undefined) && (((eventItem.startDate && eventItem.startDate + period > now) || (eventItem.status && eventItem.status === 'started')) || ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended'))) &&
                                            (user && user.id && !ticketId || eventItem.uid && !user && (((eventItem.startDate && eventItem.startDate + period > now) || (eventItem.status && eventItem.status === 'started')) || ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended'))) && !ticketId)) && isIOSPrice(((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended')) ? eventItem.price : eventItem.archivePrice)) ?
                                            ((eventItem.noOfTickets > eventSoldTickets || ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended'))) ?
                                                <Button
                                                    type='default'
                                                    className='btn-event-page'
                                                    onClick={() => this.handleBuyTicket(ticketId, eventItem)}
                                                    disabled={checkRecordingProcessing(eventItem)}
                                                >
                                                    {((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended')) ?
                                                        eventItem.archivePrice ? `watch for $${eventItem.archivePrice}` : checkRecordingProcessing(eventItem) ? 'Generating recording...' : 'watch'
                                                        : eventItem.price ? `get ticket for $${eventItem.price}` : checkRecordingProcessing(eventItem) ? 'Generating recording...' : 'get ticket'
                                                    }
                                                </Button>
                                                :
                                                <Button
                                                    type='default'
                                                    className='btn-event-page sold-out'
                                                    disabled={true}
                                                >
                                                    This event is sold out
                                                </Button>
                                            )
                                            : guestMode && user && !ticketId ?
                                                <Button
                                                    type='default'
                                                    className='btn-event-page'
                                                    loading={true}
                                                />
                                                : null
                                    }
                                    {this.checkShowWatchButton() && userLoaded && (ticketId && ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended')) && ((eventItem.parts && eventItem.parts.length) || checkRecordingAvailable(eventItem) || checkRecordingProcessing(eventItem)) || ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended')) && ticketId && ((eventItem.parts && eventItem.parts.length) || checkRecordingAvailable(eventItem) || checkRecordingProcessing(eventItem)) || eventItem.uid && user && user.uid && user.uid === eventItem.uid && ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended')) && eventItem.status && eventItem.status !== 'cancelled' && ((eventItem.parts && eventItem.parts.length) || checkRecordingAvailable(eventItem) || checkRecordingProcessing(eventItem))) ?
                                        checkRecordingProcessing(eventItem) ?
                                            <Button
                                                type='default'
                                                className='btn-event-page'
                                                disabled
                                            >
                                                Generating recording...
                                            </Button>
                                            :
                                            <Button
                                                type='default'
                                                className='btn-event-page'
                                                onClick={() => this.handleShowVideoWrapper()}
                                            >
                                                watch
                                            </Button>
                                        : null
                                    }
                                    {this.checkShowWatchButton() && userLoaded && (ticketId && eventItem.uid && user && user.uid && ((user.uid === eventItem.uid) || (firebaseClient.checkCoHost(eventItem, user.uid))) && ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended')) && eventItem.status && eventItem.status !== 'cancelled' && ((eventItem.parts && eventItem.parts.length) || checkRecordingAvailable(eventItem) || checkRecordingProcessing(eventItem))) ?
                                        !checkRecordingProcessing(eventItem) ?
                                            <Button
                                                type='default'
                                                className='btn-event-page'
                                                disabled={disableGetRecording}
                                                onClick={() => this.openLinks(eventItem)}
                                            >
                                                Get Recording
                                            </Button>
                                            : null
                                        : null
                                    }
                                    {this.checkShowWatchButton() && userLoaded && (ticketId && eventItem.uid && user && user.uid && ((user.uid === eventItem.uid) || (firebaseClient.checkCoHost(eventItem, user.uid))) && ((eventItem.type && eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended')) && eventItem.status && eventItem.status !== 'cancelled' && ((eventItem.parts && eventItem.parts.length) || checkRecordingAvailable(eventItem) || checkRecordingProcessing(eventItem))) ?
                                        !checkRecordingProcessing(eventItem) ?
                                            <Button
                                                type='default'
                                                className='btn-event-page'
                                                disabled={disableGetAudio}
                                                onClick={() => this.openLinks(eventItem, 'audio')}
                                            >
                                                Get Audio
                                            </Button>
                                            : null
                                        : null
                                    }
                                    {ticketId && eventItem.uid && user && user.uid && user.uid !== eventItem.uid && eventItem.status && eventItem.status !== 'cancelled' && eventItem.type && (((eventItem.type === 'stream') || (eventItem.status && eventItem.status === 'ended')) || (eventItem.startDate && eventItem.startDate < now) || (eventItem.status === 'started')) ?
                                        <Button
                                            type='default'
                                            className='btn-event-page'
                                            onClick={() => this.handleClickFeedback(eventItem)}
                                        >
                                            feedback
                                        </Button>
                                        : null
                                    }
                                </div>
                                {eventItem.cohosts && eventItem.cohosts.length ?
                                    <div className='event-page-cohosts'>
                                        <p className='cohosts-title'>{eventItem.cohosts.length > 1 ? 'Co-Hosts' : 'Co-Host'}</p>
                                        <div className='cohosts-wrapper'>
                                            {eventItem.cohosts.map((item, index) => {
                                                return (
                                                    <Link to={`/profile/${item.id}`} title={item.displayName} key={index} className='event-cohost-details'>
                                                        <img className='cohost-img' src={item.photoURL} loading='lazy' />
                                                        <div className='cohost-name'>{item.displayName}</div>
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : null
                                }
                                {eventItem.guestSpeakers && eventItem.guestSpeakers.length ?
                                    <div className='event-page-cohosts'>
                                        <p className='cohosts-title'>{eventItem.guestSpeakers.length > 1 ? 'Guests' : 'Guest'}</p>
                                        <div className='cohosts-wrapper'>
                                            {eventItem.guestSpeakers.map((item, index) => {
                                                return (
                                                    <Link to={`/profile/${item.id}`} title={item.displayName} key={index} className='event-cohost-details'>
                                                        <img className='cohost-img' src={item.photoURL} loading='lazy' />
                                                        <div className='cohost-name'>{item.displayName}</div>
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Loader
                        type="page"
                        text="Loading"
                        dots
                    />
                }
                <Cards
                    title='OTHER EVENTS BY THE HOST'
                    videos={uevents}
                    readMoreLink='/browse?b=upcoming-streams'
                />
                {showBecomeAHost && (!auth || !user || (user && !user.broadcaster && !user.administrator)) ?
                    <BecomeHost />
                    : null
                }
                {paymentWrapper && eventItem && user &&
                    <PaymentWrapper
                        item={eventItem}
                        resetValue={() => this.handleResetDefaultValue()}
                        successFunction={(id) => this.handleShowStreamWrapper(id)}
                    />
                }
                {tipWrapper && eventItem && eventItem.acceptTips && user &&
                    <TipWrapper
                        item={eventItem}
                        resetValue={() => this.handleResetDefaultValue()}
                        successFunction={() => this.setState({ tipWrapper: false })}
                    />
                }
                {videoWrapper && eventItem &&
                    <VideoWrapper
                        item={eventItem}
                        closeVideoWrapper={() => this.handleResetDefaultValue()}
                        loadVideo={loadVideo}
                    />
                }
                {feedback && feedbackItem &&
                    <Feedback
                        close={() => this.closeFeedback()}
                        item={feedbackItem}
                    />
                }
                {cancelModal && eventItem ?
                    <div className='vanity-modal-wrapper'>
                        <ClickOutside
                            className='vanity-modal-div'
                            clickOutside={() => this.setState({ cancelModal: false })}
                        >
                            <div className='vanity-modal-title'>Are you sure?</div>
                            <div className='vanity-modal-text'>The {eventItem && eventItem.type == 'event' ? 'event' : 'stream'} will be permanently deleted!</div>
                            <div className='vanity-modal-buttons'>
                                <Button
                                    type='default'
                                    className='btn-modal-accepted'
                                    disabled={deleting}
                                    onClick={() => this.handleDeleteItem(eventItem.id, eventItem.type, process.env.website)}
                                    loading={deleting}
                                >
                                    Delete
                                </Button>
                                <Button
                                    type='text-blue'
                                    className='btn-modal-cancel'
                                    onClick={() => this.setState({
                                        cancelModal: false
                                    })}
                                    disabled={deleting}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </ClickOutside>
                    </div>
                    : null
                }
                {hideModal && eventItem && user && user.uid ?
                    <div className='vanity-modal-wrapper'>
                        <ClickOutside
                            className='vanity-modal-div'
                            clickOutside={() => this.setState({ hideModal: false })}
                        >
                            <div className='vanity-modal-title'>Are you sure?</div>
                            <div className='vanity-modal-text'>The {eventItem && eventItem.type == 'event' ? 'event' : 'stream'} will be hidden from your profile page permanently.</div>
                            <div className='vanity-modal-buttons'>
                                <Button
                                    type='default'
                                    className='btn-modal-accepted'
                                    disabled={deleting}
                                    onClick={() => this.handleHideItem(eventItem.id, user.uid)}
                                    loading={deleting}
                                >
                                    Hide
                                </Button>
                                <Button
                                    type='text-blue'
                                    className='btn-modal-cancel'
                                    onClick={() => this.setState({
                                        hideModal: false
                                    })}
                                    disabled={deleting}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </ClickOutside>
                    </div>
                    : null
                }
                {guestModal && eventItem ?
                    <div className='vanity-modal-wrapper'>
                        <ClickOutside
                            className='vanity-modal-div'
                            clickOutside={() => this.setState({ guestModal: false, error: '' })}
                        >
                            <div className='vanity-modal-title'>Guest link</div>
                            <div className='vanity-modal-text pb-none'>Send this link only to people that you want to participate in the live show.</div>
                            <div className='vanity-modal-text sm'>(Use the Copy Audience link button to copy the link that you advertise to your audience)</div>
                            {error && <p className='vanity-modal-text pt-none pb-none error'>{error}</p>}
                            <div className='vanity-modal-buttons'>
                                <Button
                                    type='default'
                                    className='btn-modal-accepted'
                                    disabled={processing || !guestLink}
                                    onClick={() => this.handleCopyGuestLink(this.secondTooltip)}
                                    loading={processing}
                                >
                                    Copy guest link
                                </Button>
                                <Button
                                    type='text-blue'
                                    className='btn-modal-cancel'
                                    onClick={() => this.setState({
                                        guestModal: false,
                                        error: ''
                                    })}
                                    disabled={processing}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </ClickOutside>
                    </div>
                    : null
                }
                {guestNameForm && eventItem ?
                    <div className='vanity-modal-wrapper'>
                        <div className='vanity-modal-div'>
                            <div className='vanity-modal-title'>WELCOME!</div>
                            <p className='vanity-text'>
                                Welcome to the event
                                {eventItem.title ? <span>{` ${eventItem.title}`}</span> : null}
                            </p>
                            <p className='vanity-text'>Please enter your display name, click JOIN and we’ll let the host know you’re here.</p>
                            <input
                                className='vanity-modal-input'
                                value={guestName}
                                onChange={this.handleChangeGuestName}
                                type='text'
                                placeholder='Your name'
                            />
                            {/* <div className='vanity-modal-text sm pb-20'>(Let host(s) know you wish to join)</div> */}
                            <div className='vanity-modal-buttons'>
                                <Button
                                    type='default'
                                    className='btn-modal-accepted'
                                    onClick={this.joinAnonymous}
                                    disabled={!guestName || !entryApproved || (guestName && guestName.length && guestName.length < 2)}
                                >
                                    Join
                                </Button>
                                <Button
                                    type='text-blue'
                                    className='btn-modal-cancel'
                                    onClick={() => {
                                        this.setState({
                                            guestNameForm: false,
                                            guestName: ''
                                        });
                                        this.props.showLogIn(true);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </div>
                    : null
                }
                {message &&
                    <Message
                        handleShowMessage={() => this.setState({ message: !message })}
                        messageText={messageText}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appStage: state.app.stage,
        orientation: state.app.orientation,
        fanoutState: state.app.fanoutState,
        auth: state.firebase.auth,
        user: state.firebase.user,
        userLoaded: state.firebase.userLoaded,
        tickets: state.mystuff.tickets,
        upcomingEvents: state.firebase.events.upcoming
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addProcessingTicket: (data) => {
            dispatch(mystuffActions.addProcessingTicket(data));
        },
        showLogIn: (value) => {
            dispatch(appActions.showLogIn(value));
        },
        processingPayment: () => {
            dispatch(paymentActions.processingConfirmPayment());
        },
        successPayment: () => {
            dispatch(paymentActions.successConfirmPayment());
        },
        failedPayment: (error) => {
            dispatch(paymentActions.failedConfirmPayment(error));
        },
        resetPaymentValue: () => {
            dispatch(paymentActions.resetPaymentIntents());
        },
        setEventItem: (value) => {
            dispatch(roomActions.setEventItem({ eventItem: value }));
        },
        pushToHome: () => {
            dispatch(nativePush('/'));
        }
    };
};

const EventFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EventForm);

export default EventFormContainer;