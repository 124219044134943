import React, { Component } from 'react';
import classNames from "classnames";
import VideoBox from '../../Common/VideoBox';

class LayoutTiles extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render() {
        const { layout, getTilesNumber, getTilesVideo, labels, onOrientationChanged, screenShare, disableTrackHandler } = this.props;

        return (
            <div
                className={classNames(`LayoutView ${layout}`, { 'screen-share': screenShare })}
                data-number-user={`${getTilesNumber ? getTilesNumber('horizontal') : 0}/${getTilesNumber ? getTilesNumber('vertical') : 0}`}
            >
                <div className='Section-1'>
                    <div className="tiles-list">
                        {getTilesVideo && getTilesVideo('horizontal').length ?
                            getTilesVideo('horizontal').map((item, index) => {
                                return (
                                    <div key={index} className='tile-item'>
                                        <VideoBox
                                            className={`${item.type}`}
                                            source={item.stream}
                                            track={item.track}
                                            picture={item.avatar}
                                            producerId={item.producerId}
                                            autoPlay={true}
                                            muted={true}
                                            loop={true}
                                            name={((!item.srcType) || (item.srcType && item.srcType !== 'screenshare')) ? item.name : ''}
                                            labels={labels}
                                            orientation={'horizontal'}
                                            onOrientationChanged={onOrientationChanged}
                                            loading
                                            speaking={item.speaking}
                                            disableLBM={screenShare}
                                            disableTrackHandler={disableTrackHandler}
                                        />
                                    </div>
                                )
                            })
                            : null
                        }
                    </div>
                </div>
                <div className='Section-2'>
                    <div className="tiles-list">
                        {getTilesVideo && getTilesVideo('vertical').length ?
                            getTilesVideo('vertical').map((item, index) => {
                                return (
                                    <div key={index} className='tile-item'>
                                        <VideoBox
                                            className={`${item.type}`}
                                            source={item.stream}
                                            track={item.track}
                                            picture={item.avatar}
                                            producerId={item.producerId}
                                            autoPlay={true}
                                            muted={true}
                                            loop={true}
                                            name={((!item.srcType) || (item.srcType && item.srcType !== 'screenshare')) ? item.name : ''}
                                            labels={labels}
                                            orientation={'vertical'}
                                            onOrientationChanged={onOrientationChanged}
                                            loading
                                            speaking={item.speaking}
                                            disableLBM={screenShare}
                                            disableTrackHandler={disableTrackHandler}
                                        />
                                    </div>
                                )
                            })
                            : null
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default LayoutTiles;