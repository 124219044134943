import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { push as nativePush } from 'connected-react-router';
import * as paymentActions from "../../actions/payment_actions";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import firebaseClient from '../../utils/FirebaseClient';
import Loader from '../Widget/Loader';
import Button from '../Widget/Button';
import arrowIcon from '../../../resources/assets/ArrowLeft.svg';
import { returnStartDate, checkEventPrice, renderCategory } from "../../utils/text";

let stripe_pk = process.env.build == 'prod' ? process.env.stripe_pk_prod : process.env.build == 'test' || process.env.build == 'ovi' ? process.env.stripe_pk_test : process.env.build == 'nfl-aa' ? null : null;
let stripePK = null;

if (stripe_pk) {
    stripePK = loadStripe(stripe_pk);
}

async function confirmCard(stripe, elements, clientSecret, processingPayment, successPayment, failedConfirmPayment, successFunction, resetPaymentValue) {

    let cardElement = elements.getElement(CardElement);

    if (cardElement && clientSecret) {

        processingPayment();

        const confirmedCardPayment = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement
            }
        });

        const { paymentIntent, error } = confirmedCardPayment;

        if (error) {
            if (failedConfirmPayment)
                failedConfirmPayment(error);
        } else if (paymentIntent && paymentIntent.status && paymentIntent.status === 'succeeded') {
            cardElement.clear();
            successPayment();
            setTimeout(() => {
                successFunction(paymentIntent.id);
                resetPaymentValue();
            }, 5000);
        }
    }
};

class PaymentWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardComplete: false,
            soldTickets: 0
        }
        this.handlePaymentError = this.handlePaymentError.bind(this);
        this.handleChangeSoldTicketsNumber = this.handleChangeSoldTicketsNumber.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    componentDidMount() {
        const { item, createPaymentIntents } = this.props;
        let ticketType = '', ticketPrice = null;

        if (item && item.type && item.type === "event" && item.status !== 'ended') {
            ticketType = 'event-ticket';
            ticketPrice = item.price;
        } else if (item && item.type && ((item.type === "stream") || (item.status && item.status === 'ended'))) {
            ticketType = 'stream-ticket';
            ticketPrice = item.archivePrice;
        } else {
            ticketType = 'event-ticket';
            ticketPrice = item.price;
        }

        if (createPaymentIntents && item && item.id && ticketPrice && ticketType) {
            createPaymentIntents('ticket', item.id, ticketType)
        }

        if (item && item.id) {
            firebaseClient.listenToSoldTicket(item, this.handleChangeSoldTicketsNumber);
        }
    }

    componentWillUnmount() {
        const { item } = this.props;

        if (item && item.id) {
            firebaseClient.stopListeningToSoldTicket(item);
        }
    }

    handleChangeSoldTicketsNumber(number, type) {
        if (type && type === 'event') {
            this.setState({
                soldTickets: number
            });
        }
    }

    handleChangeCardInput(e) {
        this.setState({
            cardComplete: e.complete
        });
    }

    handlePay(stripe, elements) {
        const { clientSecret, processingPayment, successPayment, successFunction, resetPaymentValue } = this.props;

        this.setState({
            error: null,
        });

        confirmCard(stripe, elements, clientSecret, processingPayment, successPayment, this.handlePaymentError, successFunction, resetPaymentValue);
    };

    handleReserve() {
        const { processingPayment, successPayment, successFunction, resetPaymentValue, item } = this.props;
        if (item) {
            processingPayment();
            firebaseClient.reserveTicket(item).then((res) => {
                if (res) {
                    successPayment();
                    setTimeout(() => {
                        successFunction(res.data.paymentId)
                        resetPaymentValue();
                    }, 5000);
                }
            });
        }
    };

    handleBack() {
        const { resetValue, resetPaymentValue } = this.props;

        if (resetValue && resetPaymentValue) {
            resetPaymentValue();
            resetValue();
            this.setState({
                cardComplete: false
            });
        }
    }

    handlePushToChannel(uid, event) {
        event.preventDefault();
        event.stopPropagation();

        const { pushToChannel } = this.props;

        if (pushToChannel) {
            pushToChannel(uid);
        }
    }

    handlePushToCategory(cat, event) {
        event.preventDefault();
        event.stopPropagation();

        const { pushToCategory } = this.props;

        if (pushToCategory) {
            pushToCategory(cat.toLowerCase());
        }
    }

    handlePaymentError(error) {
        console.error('Payment error', error);
        const { failedConfirmPayment, failedPayment } = this.props;
        if (failedConfirmPayment)
            failedConfirmPayment(error);
        if (failedPayment)
            failedPayment(error);
        this.setState({
            error: error,
            processing: false
        });
    }

    render() {
        const { cardComplete, soldTickets } = this.state;
        const { processing, succeeded, clientSecret, item } = this.props;
        let error = this.props.error || this.state.error;

        return (
            <div className='payment-page-overlay'>
                {item ?
                    <div className='payment-page-wrapper'>
                        <div className='payment-page-details' style={{ backgroundImage: `url(${firebaseClient.getEventHorizontalPicture(item)})` }}>
                            <img
                                src={arrowIcon}
                                loading='lazy'
                                className='payment-back-icon'
                                onClick={this.handleBack}
                            />
                            <div className='payment-details'>
                                <p className='payment-title'>{item.title}</p>
                                <Link
                                    to={`/browse?b=${item.cat.toLowerCase()}`}
                                    className='payment-category'
                                >
                                    {renderCategory(item.cat)}
                                </Link>
                                <Link to={`/profile/${item.uid}`} className='payment-author-details'>
                                    <img className='author-img' src={item.authorPic} loading='lazy' />
                                    <div className='author-name'>{item.author}</div>
                                </Link>
                                {((item.type && item.type === 'stream') || (item.status && item.status === 'ended')) ?
                                    <p className='payment-date'>{item && item.startDate ? `${moment(new Date(item.startDate)).format('LL')}` : ''}</p>
                                    :
                                    <p className='payment-date'>{item && item.startDate ? `Start ${moment(new Date(item.startDate)).format('LL')} | ${moment(new Date(item.startDate)).format('LT')}` : ''}</p>
                                }
                            </div>
                        </div>
                        <div className='payment-form-wrapper'>
                            <div className='payment-form-div-wrapper'>
                                <div className='payment-form-div'>
                                    <div className='payment-form-item'>
                                        <p className='payment-form-title'>Date</p>
                                        <p className='payment-form-value'>{item ? moment(new Date(returnStartDate(item))).format('LL') : null}</p>
                                    </div>
                                    <div className='payment-form-item'>
                                        <p className='payment-form-title'>Begins</p>
                                        <p className='payment-form-value'>{item ? moment(new Date(returnStartDate(item))).format('LT') : null}</p>
                                    </div>
                                </div>
                                <div className='payment-form-div'>
                                    <div className='payment-form-item'>
                                        <p className='payment-form-title'>Title</p>
                                        <p className='payment-form-value'>{item.title}</p>
                                    </div>
                                    {!succeeded && <div className='payment-form-item'>
                                        <p className='payment-form-title'>Price</p>
                                        <p className='payment-form-value'>{item && item.type && item.type === 'event' && item.status !== 'ended' && item.price && item.price.toFixed ? `${item.price.toFixed(2)} USD` : item && item.type && ((item.type === 'stream') || (item.status && item.status === 'ended')) && item.archivePrice && item.archivePrice.toFixed ? `${item.archivePrice.toFixed(2)} USD` : ''}</p>
                                    </div>}
                                </div>
                            </div>
                            <div className='payment-form-stripe'>
                                {succeeded ?
                                    <div className='stripe-wrapper'>
                                        <p className='stripe-text'>{`Thank you for ${checkEventPrice(item) ? 'purchasing' : 'reserving'} your ticket!`}</p>
                                        <p className='stripe-text'>You will receive a confirmation email shortly.</p>
                                        <p className='stripe-text'>Your ticket can be found on your MyStuff Page!</p>
                                    </div>
                                    : processing && item && !checkEventPrice(item) ?
                                        <div className='stripe-wrapper'>
                                            <p className='stripe-text'>Reserving ticket</p>
                                            <Button
                                                type='default'
                                                className='btn-reserve-ticket'
                                                disabled={processing}
                                                loading={processing}
                                            >
                                                Reserve Ticket
                                            </Button>
                                        </div>
                                        : item.noOfTickets && item.noOfTickets <= soldTickets && item.type && item.type !== 'stream' && !processing ?
                                            <div className='stripe-wrapper'>
                                                <p className='stripe-text'>This event is sold out.</p>
                                                <p className='stripe-text'>Thank you for your understanding.</p>
                                            </div>
                                            : !checkEventPrice(item) && (item.noOfTickets > soldTickets || item.type && ((item.type === 'stream') || (item.status && item.status === 'ended'))) ?
                                                <div className='stripe-wrapper'>
                                                    <p className='stripe-text'>Reserve your ticket now!</p>
                                                    <Button
                                                        type='default'
                                                        className='btn-reserve-ticket'
                                                        onClick={() => this.handleReserve()}
                                                        disabled={processing}
                                                        loading={processing}
                                                    >
                                                        Reserve Ticket
                                                    </Button>
                                                    <p className='stripe-text'>
                                                        {`Like the show? Subscribe to `}
                                                        <Link
                                                            to={item.uid ? `/profile/${item.uid}` : '/'}
                                                            onClick={this.handleBack}
                                                        >
                                                            {item.author ? item.author : 'host'}
                                                        </Link>.
                                                    </p>
                                                </div>
                                                : stripePK ?
                                                    <div className='stripe-wrapper'>
                                                        <Elements stripe={stripePK}>
                                                            <ElementsConsumer>
                                                                {({ stripe, elements }) => (
                                                                    <div>
                                                                        <div className='input-div'>
                                                                            <p>Card details</p>
                                                                            <CardElement
                                                                                id='card-element'
                                                                                options={{
                                                                                    hidePostalCode: true,
                                                                                    style: {
                                                                                        base: {
                                                                                            color: '#ffffff',
                                                                                            fontSize: '18px',
                                                                                            fontWeight: 300,
                                                                                            '::placeholder': {
                                                                                                color: '#9fa5b5',
                                                                                            },
                                                                                            ':disabled': {
                                                                                                color: '#343a40',
                                                                                            },
                                                                                        }
                                                                                    },
                                                                                    disabled: processing
                                                                                }}
                                                                                onChange={(e) => this.handleChangeCardInput(e)}
                                                                            />
                                                                        </div>
                                                                        <Button
                                                                            type='default'
                                                                            className='btn-pay-ticket'
                                                                            onClick={() => this.handlePay(stripe, elements)}
                                                                            disabled={!cardComplete || processing || !clientSecret}
                                                                            loading={processing || !clientSecret}
                                                                        >
                                                                            Process Payment
                                                                    </Button>
                                                                        <div className='stripe-message-wrapper'>
                                                                            {error && error.message ?
                                                                                <p className='stripe-message error-text'>{error.message}</p>
                                                                                : error ?
                                                                                    <p className='stripe-message error-text'>Payment Failed</p>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </ElementsConsumer>
                                                        </Elements>
                                                        <p className='stripe-text'>
                                                            {`Like the show? Subscribe to `}
                                                            <Link
                                                                to={item.uid ? `/profile/${item.uid}` : '/'}
                                                                onClick={this.handleBack}
                                                            >
                                                                {item.author ? item.author : 'host'}
                                                            </Link>.
                                                    </p>
                                                    </div>
                                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Loader />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        processing: state.payment.state === "PAYMENT_STATE_PROCESSING",
        succeeded: state.payment.state === "PAYMENT_STATE_SUCCESS",
        error: state.payment.error,
        clientSecret: state.payment.clientSecret
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createPaymentIntents: (type, streamId, ticketType) => {
            dispatch(paymentActions.createPaymentIntents({ type, streamId, ticketType }));
        },
        processingPayment: () => {
            dispatch(paymentActions.processingConfirmPayment());
        },
        successPayment: () => {
            dispatch(paymentActions.successConfirmPayment());
        },
        failedPayment: (error) => {
            dispatch(paymentActions.failedConfirmPayment(error));
        },
        resetPaymentValue: () => {
            dispatch(paymentActions.resetPaymentIntents());
        },
        pushToChannel: (uid) => {
            dispatch(nativePush(`/profile/${uid}`));
        },
        pushToCategory: (category) => {
            dispatch(nativePush(`/browse?b=${category}`));
        }
    };
};

const PaymentWrapperContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentWrapper);

export default PaymentWrapperContainer;
