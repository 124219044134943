import React, { Component } from 'react';
import { connect } from "react-redux";
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ProfileList from "../Header/ProfileList";
import Button from "../Widget/Button";
import goLiveIcon from '../../../resources/assets/go-live.png';
import walletIcon from '../../../resources/assets/wallet.png';

class LogInBtn extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            handleShowLogIn,
            activeTab,
            auth,
            history,
            roles
        } = this.props;

        return (
            <div className={classNames('logInBtn', { 'nfl-aa': process.env.build && process.env.build === 'nfl-aa' })}>
                {(process.env.build && process.env.build === 'ovi') ?
                    <div className="social-media-links-header ovi-button">
                        {((!process.env.build) || (process.env.build && process.env.build !== 'nfl-aa')) && auth && roles && roles.administrator ?
                            <Link
                                className="facebook-icon"
                                to='/calendar?gm=1'
                            >
                                <img src={goLiveIcon} alt='go-live' loading='lazy' className='login-icon' />
                            </Link>
                            : null
                        }
                        <Link
                            className="facebook-icon"
                            to='#'
                        >
                            <img src={walletIcon} alt='wallet' loading='lazy' className='login-icon' />
                        </Link>
                    </div>
                    : null
                }
                {!auth ?
                    <Button
                        type='text'
                        className='btn-logIn'
                        onClick={() => handleShowLogIn()}
                    >
                        log in
                    </Button>
                    :
                    <ProfileList
                        activeTab={activeTab}
                        history={history}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth,
        roles: state.firebase.roles,
    };
};

const LogInBtnContainer = connect(
    mapStateToProps,
    null //mapDispatchToProps
)(LogInBtn);

export default LogInBtnContainer;
