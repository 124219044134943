import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Copyright from './Copyright';
import tikTokIcon from "../../../resources/assets/tiktok.svg";
import {
    stringToBoolean,
    dudaLink,
    facebookLink,
    twitterLink,
    instagramLink,
    youtubeLink,
    linkedInLink,
    tikTokLink,
} from '../../utils/text';

class Footer extends Component {

    constructor(props) {
        super(props)
        this.state = {
            supportSubscription: false,
            supportHosting: false
        }
    }

    componentDidMount() {
        const { history } = this.props;
        if (history) {
            history.listen((location) => {
                if (location && location.pathname && this.props.user && this.props.user.uid) {
                    this.setState({
                        supportSubscription: location.pathname.indexOf('/mystuff') !== -1,
                        supportHosting: location.pathname.indexOf('/edit-profile') !== -1
                    });
                }
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user && this.props.user && this.props.user.uid && window && window.location && window.location.pathname) {
            this.setState({
                supportSubscription: window.location.pathname.indexOf('/mystuff') !== -1,
                supportHosting: window.location.pathname.indexOf('/edit-profile') !== -1
            });
        }
    }

    render() {
        const { supportSubscription, supportHosting } = this.state;
        const { subscribedToHosts, user, hideNav } = this.props;
        return (
            <footer className="footer">
                {(!process.env.hideSubscription || process.env.hideSubscription && !stringToBoolean(process.env.hideSubscription)) && (supportSubscription && subscribedToHosts && subscribedToHosts.length || supportHosting && user && user.broadcaster) ?
                    <div className='footer-unsubscribe'>
                        To unsubscribe, please contact <a target="_blank" href={`${dudaLink}/help#ContactUs`}>support</a>.
                    </div>
                    : null}
                <div className='footer-links'>
                    <div className='links-item'>
                        {dudaLink ?
                            <a href={`${dudaLink}/help`}>Help</a>
                            :
                            <Link to="/help">Help</Link>
                        }
                        
                    </div>
                    <div className='links-item'>
                        {dudaLink ?
                           <a href={`${dudaLink}/help#ContactUs`}>Contact Us</a>
                            :
                            <Link to="/contact">Contact Us</Link>
                        }
                    </div>
                    <div className='links-item'>
                        {dudaLink ?
                            <a href={`${dudaLink}/privacy-policy`}>Privacy Policy</a>
                            :
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        }
                    </div>
                    <div className='links-item'>
                        {dudaLink ?
                            <a href={`${dudaLink}/terms`}>Terms of Service</a>
                            :
                            <Link to="/terms">Terms of Service</Link>
                        }
                    </div>
                </div>
                <Copyright />
                {
                    (process.env.build && process.env.build === 'nfl-aa') ?
                        <div className="social-media-links-footer">
                            {facebookLink ?
                                <a
                                    className="facebook-icon fa fa-facebook"
                                    href={facebookLink}
                                    target="_blank"
                                />
                                : null
                            }
                            {twitterLink ?
                                <a
                                    className="twitter-icon fa fa-twitter"
                                    href={twitterLink}
                                    target="_blank"
                                />
                                : null
                            }
                            {instagramLink ?
                                <a
                                    className="instagram-icon fa fa-instagram"
                                    href={instagramLink}
                                    target="_blank"
                                />
                                : null
                            }
                            {youtubeLink ?
                                <a
                                    className="youtube-icon fa fa-youtube-play"
                                    href={youtubeLink}
                                    target="_blank"
                                />
                                : null
                            }
                            {linkedInLink ?
                                <a
                                    className="linkedIn-icon fa fa-linkedin-square"
                                    href={linkedInLink}
                                    target="_blank"
                                />
                                : null
                            }
                            {tikTokLink ?
                                <a
                                    className="tiktok-icon"
                                    href={tikTokLink}
                                    target="_blank"
                                >
                                    <img src={tikTokIcon} loading='lazy' />
                                </a>
                                : null
                            }
                        </div>
                        : null
                }
            </footer >
        );
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.firebase.user,
        subscribedToHosts: state.mystuff.subscribedToHosts,
    };
};

const FooterContainer = connect(
    mapStateToProps,
    null // mapDispatchToProps
)(Footer);

export default FooterContainer;