import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push as nativePush } from 'connected-react-router';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import classNames from "classnames";
import ReactTooltip from 'react-tooltip';
import ContentLoader from 'react-content-loader';
import VideoWrapper from '../Pages/VideoWrapper';
import PaymentWrapper from '../Payment/PaymentWrapper';
import Button from '../Widget/Button';
import firebaseClient from '../../utils/FirebaseClient';
import linkIcon from '../../../resources/assets/link5.svg';
import arrowIcon from '../../../resources/assets/arrow.png';
import defaultAvatar from '../../../resources/assets/user-placeholder.png';
import * as mystuffActions from "../../actions/mystuff_actions";
import * as firebaseActions from "../../actions/firebase_actions";
import * as appActions from "../../actions/app_actions";
import {
    toSeoUrl,
    returnStartDate,
    appLauncherOpenVanityUrl,
    hostColors,
    isIOSPrice,
    checkEventPrice,
    renderCategory,
    validShowEndedEvent,
} from "./../../utils/text";

class Cards extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalType: '',
            modalItem: '',
            videoWrapper: false,
            videoItem: null,
            paymentWrapper: false,
            paymentItem: null,
            ticketsLoaded: false,
            scrollLeftDisabled: true,
            scrollRightDisabled: false
        }

        this.scrollRef = React.createRef();
    }

    componentDidMount() {
        const { appStage } = this.props;

        if (appStage && appStage === appActions.APP_STAGE_LOADED) {
            this.setState({
                ticketsLoaded: true
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { appStage } = this.props;

        if (appStage && prevProps.appStage !== appStage && appStage === appActions.APP_STAGE_LOADED) {
            this.setState({
                ticketsLoaded: true
            });
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    }

    unfollow(uid) {
        let btn = document.getElementById(`btn-unfollow-${uid}`);
        if (btn) {
            btn.style.pointerEvents = 'none';
            btn.style.opacity = '0.6';
        }
        return firebaseClient.unfollow(uid);
    }

    removeVideo(id) {
        const { deleteHiddenVideo } = this.props;
        let btn = document.getElementById(`btn-delete-${id}`);
        if (btn) {
            btn.style.pointerEvents = 'none';
            btn.style.opacity = '0.6';
        }
        if (deleteHiddenVideo && id) {
            deleteHiddenVideo(id);
        }
    }

    handleShowModal(value, type, item) {
        this.setState({
            modal: value,
            modalType: type,
            modalItem: item
        });
    }

    handleAcceptedModal() {
        const { modalItem, modalType } = this.state;
        if (modalItem) {
            if (modalType === 'unfollow') {
                this.unfollow(modalItem.uid);
            } else if (modalType === 'hide') {
                this.handleHideVideo(modalItem);
            }
        }
        this.setState({
            modal: false,
            modalItem: ''
        })
    }

    handleHideVideo(video) {
        const { hideVideo } = this.props;
        let btn = document.getElementById(`btn-hide-${video.id}`);
        if (btn) {
            btn.style.pointerEvents = 'none';
            btn.style.opacity = '0.6';
        }
        if (hideVideo && video) {
            hideVideo(video);
        }
    }

    handlePushToChannel(uid, event) {
        event.preventDefault();
        event.stopPropagation();

        const { pushToChannel } = this.props;

        if (pushToChannel) {
            pushToChannel(uid);
        }
    }

    handlePushToCategory(cat, event) {
        event.preventDefault();
        event.stopPropagation();

        const { pushToCategory } = this.props;

        if (pushToCategory) {
            pushToCategory(cat.toLowerCase());
        }
    }

    handleBuyTicket(item, event) {
        event.preventDefault();
        event.stopPropagation();

        const { user, showLogIn, pushToLink } = this.props;

        if (item && item.id && !checkEventPrice(item) && pushToLink) {
            pushToLink(`/event/${item.id}?video=1`);
        } else if (item && item.noOfTickets) {
            if (process.env.platform && process.env.platform === 'mobile' && item && item.type && item.id && checkEventPrice(item)) {
                if (appLauncherOpenVanityUrl) {
                    appLauncherOpenVanityUrl(item.type, item.id);
                }
            } else {
                this.setState({
                    paymentWrapper: true,
                    paymentItem: item,
                }, () => {
                    if (!user && showLogIn) {
                        showLogIn(true);
                    }
                });
            }
        }

        // console.log('Buy ticket', item.id);
    }

    handleResetDefaultValue() {
        this.setState({
            paymentWrapper: false,
            videoWrapper: false,
            paymentItem: null,
            videoItem: null
        });
    }

    handleShowStreamWrapper(id) {

        const { paymentItem } = this.state;
        const { user, addProcessingTicket, pushToLink } = this.props;

        let ticket = {};

        ticket.id = paymentItem.id + "_processing";
        ticket.eventId = paymentItem.id;
        ticket.paymentId = id;
        ticket.paymentStatus = "in_processing";
        ticket.status = "processing";
        ticket.type = `${paymentItem.type}-ticket`;
        ticket.role = "listener";
        ticket.userId = user.uid;

        if (addProcessingTicket) {
            addProcessingTicket(ticket);
        }

        if (pushToLink && paymentItem && paymentItem.type && paymentItem.id) {
            pushToLink(`/${paymentItem.type}/${paymentItem.id}`)
        }
    }

    handleScroll(scrollOffset) {
        if (this.scrollRef && this.scrollRef.current) {

            // console.log('SCROLL', this.scrollRef.current.scrollLeft, this.scrollRef.current.clientWidth, this.scrollRef.current.scrollWidth);

            this.scrollRef.current.scrollTo({
                top: 0,
                left: scrollOffset > 0 ?
                    Math.min(this.scrollRef.current.scrollLeft + this.scrollRef.current.clientWidth - 200, this.scrollRef.current.scrollWidth) :
                    Math.max(this.scrollRef.current.scrollLeft - this.scrollRef.current.clientWidth + 200, 0),
                behavior: 'smooth'
            });

            if (this.timer) {
                clearTimeout(this.timer);
            }

            this.timer = setTimeout(() => {
                this.setState({
                    scrollLeftDisabled: !this.scrollRef.current.scrollLeft,
                    scrollRightDisabled: ((this.scrollRef.current.scrollWidth - this.scrollRef.current.clientWidth) === this.scrollRef.current.scrollLeft)
                });
            }, 500);

            // this.scrollRef.current.scrollLeft += scrollOffset;
        }
    }

    handleRenderUserImg(user) {
        if (user) {
            if (user.profile_picture2) return user.profile_picture2;
            else if (user.profile_picture1) return user.profile_picture1;
            else if (user.profile_picture) return user.profile_picture;
            else return defaultAvatar;
        } else {
            return defaultAvatar;
        }
    }

    handleCopy(item, event) {
        event.preventDefault();
        event.stopPropagation();

        if (item && item.id) {
            let page = item.type && item.type === 'event' ? 'event' : 'stream';
            const link = item.sharedLink
                ? item.sharedLink
                : `https://${firebaseClient.getDomain()}/${page}/${item.id}`;

            navigator.clipboard.writeText(link);
        }
    }

    render() {
        const { modal, modalType, paymentWrapper, paymentItem, videoWrapper, videoItem,
            ticketsLoaded, scrollLeftDisabled, scrollRightDisabled } = this.state;
        const { hideTitle, title, videos, hosts, grid, auth, user, tickets, mobile, readMoreLink, emptyText, id } = this.props;

        return (
            <div>
                <div
                    className={classNames('cards-wrapper', { 'grid': grid }, { 'mobile': mobile })}
                    id={id}
                >
                    {!hideTitle &&
                        <div className='cards-title-wrapper'>
                            {title && <p className='cards-title'>{title}</p>}
                            {readMoreLink && videos && videos.length && videos.length > 3 ?
                                <Link to={readMoreLink}>
                                    <Button type='text-blue' className='btn-cards-read-more'>view more</Button>
                                </Link>
                                : null
                            }
                        </div>
                    }
                    {videos && videos.length ?
                        <div ref={this.scrollRef} className={classNames('cards-list', { 'showThree': readMoreLink })}>
                            {videos.map((video, index) => {
                                return (
                                    <Link
                                        key={index}
                                        to={hosts ?
                                            `/profile/${video.id}` :
                                            `/${video.type && video.type === 'event' ?
                                                'event' :
                                                'stream'}/${video.id}/${toSeoUrl(video.title)}`
                                        }
                                        className={classNames('cards-item', { 'grid': grid }, { 'hosts': hosts })}
                                    >
                                        <div
                                            className={classNames('cards-img-wrapper', { 'small-image': ((!video) || (video && !video.branding) || (video && video.branding && !video.branding.eventSquareImage)) })}
                                            style={hosts ? { background: `linear-gradient(180deg, ${hostColors[Math.floor(hostColors.length * Math.random())]} 0%, rgba(0, 0, 0, 1) 30%)` } : {}}
                                        >
                                            <img src={hosts ? this.handleRenderUserImg(video) || video.picture : firebaseClient.getEventCardPicture(video)} loading="lazy" width='256' height='200' className='cards-img' />
                                            {!hosts ?
                                                <div className='cards-img-overlay'>
                                                    <div
                                                        className='link-img'
                                                        onClick={(e) => this.handleCopy(video, e)}
                                                        data-for="cards-copy"
                                                        data-tip='Copied!'
                                                    >
                                                        <img src={linkIcon} loading='lazy' />
                                                    </div>
                                                    <ReactTooltip
                                                        id="cards-copy"
                                                        isCapture={true}
                                                        className="cards-copy-tooltip"
                                                        place="top"
                                                        type="dark"
                                                        effect="solid"
                                                        event='mousedown'
                                                        eventOff='mouseleave'
                                                    />
                                                    <div className='cards-date'>{`${moment(new Date(returnStartDate(video))).format('L')}, ${moment(new Date(returnStartDate(video))).format('LT')}, ${moment.tz(moment.tz.guess()).zoneAbbr()}`}</div>
                                                </div>
                                                : null
                                            }
                                        </div>
                                        {hosts ?
                                            <div className='cards-info-wrapper'>
                                                <p className='cards-host-name'>{video.username}</p>
                                                {/* <p className='cards-host-cat'>{video.cat ? video.cat : ''}</p>
                                                <p className='cards-host-subscribers'>{video.subscribed ? `${video.subscribed} subscribers` : ''}</p> */}
                                            </div>
                                            :
                                            <div className='cards-info-wrapper'>
                                                <p className='cards-category' onClick={this.handlePushToCategory.bind(this, video.cat)}>{renderCategory(video.cat)}</p>
                                                <p className='cards-title' title={video.title}>{video.title}</p>
                                                <p className='cards-author' title={video.author ? video.author : ''} onClick={this.handlePushToChannel.bind(this, video.uid)}>{video.author ? video.author : ''}</p>
                                                <div className='cards-buttons'>
                                                    {ticketsLoaded && video && video.noOfTickets && video.uid && (user && user.uid && user.uid !== video.uid || !user) && !tickets[video.id] && !tickets[video.uid] && isIOSPrice(((video.type && video.type === 'stream') || (video.status && video.status === 'ended')) ? video.price : video.archivePrice) ?
                                                        (validShowEndedEvent(video, user)) ?
                                                            null
                                                            :
                                                            <Button
                                                                type='default'
                                                                className='btn-cards-ticket'
                                                                onClick={this.handleBuyTicket.bind(this, video)}
                                                            >
                                                                {video && video.type && video.type === 'event' && video.status !== 'ended' ?
                                                                    'get ticket'
                                                                    : video && video.type && ((video.type === 'stream') || (video.status && video.status === 'ended')) ?
                                                                        'watch'
                                                                        : 'get ticket'
                                                                }
                                                            </Button>
                                                        : null
                                                    }
                                                    <p className='cards-price'>{video && video.type && video.type === 'event' && video.status !== 'ended' && video.price && video.price.toFixed ? `${video.price.toFixed(2)} USD` : video && video.type && ((video.type === 'stream') || (video.status && video.status === 'ended')) && video.archivePrice && video.archivePrice.toFixed ? `${video.archivePrice.toFixed(2)} USD` : ''}</p>
                                                </div>
                                            </div>
                                        }
                                    </Link>
                                )
                            })}
                            <Modal
                                show={modal}
                                onHide={() => this.handleShowModal(false)}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Are you sure?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>{modalType === 'unfollow' ? 'The user will be permanently unfollow!' : modalType === 'hide' ? 'The video will be hidden and all comments will be deleted. Do you want to do that?' : 'The video will be permanently deleted!'}</Modal.Body>
                                <Modal.Footer>
                                    <button
                                        className="modal-btn waves-effect waves-light btn grey darken-3"
                                        onClick={() => this.handleShowModal(false)}
                                    >
                                        Back
                                    </button>
                                    <button
                                        className="modal-btn waves-effect waves-light btn red"
                                        onClick={() => this.handleAcceptedModal()}
                                    >
                                        {modalType === 'unfollow' ? 'Unfollow' : modalType === 'hide' ? 'Hide' : 'Delete'}
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        :
                        videos && videos.length === 0 ?
                            <div className='cards-empty'>{emptyText ? emptyText : "Nothing here yet"}</div>
                            :
                            <div className='cards-list'>
                                <ContentLoader backgroundColor='#6c757d' foregroundColor='#000000' viewBox={"0 0 256 360"}>
                                    <rect x="0" y="0" width={"256"} height={"360"} />
                                </ContentLoader>
                                <ContentLoader backgroundColor='#6c757d' foregroundColor='#000000' viewBox={"0 0 256 360"}>
                                    <rect x="0" y="0" width={"256"} height={"360"} />
                                </ContentLoader>
                                <ContentLoader backgroundColor='#6c757d' foregroundColor='#000000' viewBox={"0 0 256 360"}>
                                    <rect x="0" y="0" width={"256"} height={"360"} />
                                </ContentLoader>
                                <ContentLoader backgroundColor='#6c757d' foregroundColor='#000000' viewBox={"0 0 256 360"}>
                                    <rect x="0" y="0" width={"256"} height={"360"} />
                                </ContentLoader>
                                <ContentLoader backgroundColor='#6c757d' foregroundColor='#000000' viewBox={"0 0 256 360"}>
                                    <rect x="0" y="0" width={"256"} height={"360"} />
                                </ContentLoader>
                                <ContentLoader backgroundColor='#6c757d' foregroundColor='#000000' viewBox={"0 0 256 360"}>
                                    <rect x="0" y="0" width={"256"} height={"360"} />
                                </ContentLoader>
                                <ContentLoader backgroundColor='#6c757d' foregroundColor='#000000' viewBox={"0 0 256 360"}>
                                    <rect x="0" y="0" width={"256"} height={"360"} />
                                </ContentLoader>
                                <ContentLoader backgroundColor='#6c757d' foregroundColor='#000000' viewBox={"0 0 256 360"}>
                                    <rect x="0" y="0" width={"256"} height={"360"} />
                                </ContentLoader>
                                <ContentLoader backgroundColor='#6c757d' foregroundColor='#000000' viewBox={"0 0 256 360"}>
                                    <rect x="0" y="0" width={"256"} height={"360"} />
                                </ContentLoader>
                                <ContentLoader backgroundColor='#6c757d' foregroundColor='#000000' viewBox={"0 0 256 360"}>
                                    <rect x="0" y="0" width={"256"} height={"360"} />
                                </ContentLoader>
                                <ContentLoader backgroundColor='#6c757d' foregroundColor='#000000' viewBox={"0 0 256 360"}>
                                    <rect x="0" y="0" width={"256"} height={"360"} />
                                </ContentLoader>
                                <ContentLoader backgroundColor='#6c757d' foregroundColor='#000000' viewBox={"0 0 256 360"}>
                                    <rect x="0" y="0" width={"256"} height={"360"} />
                                </ContentLoader>
                            </div>
                    }
                    <div className='scroll-wrapper'>
                        {readMoreLink && videos && videos.length && videos.length > 3 ?
                            <Link to={readMoreLink}>
                                <Button type='text-blue' className='btn-cards-read-more'>show more</Button>
                            </Link>
                            : null
                        }
                        {!grid && videos && videos.length ?
                            <img
                                src={arrowIcon}
                                loading='lazy'
                                className={classNames('left-arrow', { 'opacity': scrollLeftDisabled })}
                                onClick={() => this.handleScroll(-300)}
                            />
                            : null
                        }
                        {!grid && videos && videos.length ?
                            <img
                                src={arrowIcon}
                                loading='lazy'
                                className={classNames('right-arrow', { 'opacity': scrollRightDisabled })}
                                onClick={() => this.handleScroll(300)}
                            />
                            : null
                        }
                    </div>
                </div>
                {paymentWrapper && user &&
                    <PaymentWrapper
                        item={paymentItem}
                        resetValue={() => this.handleResetDefaultValue()}
                        successFunction={(id) => this.handleShowStreamWrapper(id)}
                    />
                }
                {videoWrapper && videoItem &&
                    <VideoWrapper
                        item={videoItem}
                        closeVideoWrapper={() => this.handleResetDefaultValue()}
                        loadVideo={true}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appStage: state.app.stage,
        auth: state.firebase.auth,
        user: state.firebase.user,
        tickets: state.mystuff.tickets,
        mobile: state.app.user_agent_info.platform.type === 'mobile',
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushToChannel: (uid) => {
            dispatch(nativePush(`/profile/${uid}`));
        },
        pushToCategory: (category) => {
            dispatch(nativePush(`/browse?b=${category}`));
        },
        pushToLink: (value) => {
            dispatch(nativePush(value));
        },
        showLogIn: (value) => {
            dispatch(appActions.showLogIn(value));
        },
        addProcessingTicket: (data) => {
            dispatch(mystuffActions.addProcessingTicket(data));
        }
    };
};

const CardsContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Cards);

export default CardsContainer;

